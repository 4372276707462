import { WebserviceService } from '../../webservice.service';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';

interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

//let http = 'http://localhost:4200/#/'; //new WebserviceService();
let xhttp = new WebserviceService(null,null);
let yhttp = xhttp.getUrl();
let http = 'http://' + yhttp + '/#/';

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fa fa-home fa-fw'
  },
  {
    name: 'Search',
    url: http + 'taxclient?method=search',
    icon: 'fa fa-search fa-fw'
  },
  {
    name: 'Photos',
    url: http + 'taxclient?method=photo',
    icon: 'fa fa-camera fa-fw'
  },
  {
    name: 'New Client',
    url: http + 'taxclient?method=newclient',
    icon: 'fa fa-user fa-fw'
  },
  {
    name: 'Additional Client Data',
    url: http + 'taxclient?method=addclientdata',
    icon: 'fa fa-plus-circle fa-fw'
  },
  {
    name: 'Filter',
    url: http + 'taxclient?method=filter',
    icon: 'fa fa-filter fa-fw'
  },
  {
    name: 'Next',
    url: http + 'taxclient?method=next',
    icon: 'fa fa-share fa-fw'
  },
  {
    name: 'Previous',
    url: http + 'taxclient?method=previous',
    icon: 'fa fa-reply fa-fw'
  },
  {
    name: 'Reports',
    url: http + 'taxclient?method=report',
    icon: 'fa fa-print fa-fw'
  },
  {
    name: 'SMS',
    url: http + 'taxclient?method=sms',
    icon: 'fa fa-comments fa-fw'
  },
];
