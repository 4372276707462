import { Component, OnInit, TemplateRef, ViewContainerRef, ViewChild,Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { WebserviceService } from '../../webservice.service';

import { BsModalService } from 'ngx-bootstrap/modal';

import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { NgForm } from '@angular/forms';
import * as moment from 'moment';

import * as $ from 'jquery/dist/jquery.min.js';

import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';

@Component({
  selector: 'app-waitinglist',
  templateUrl: './waitinglist.component.html',
  styleUrls: ['./waitinglist.component.scss']
})
export class WaitinglistComponent implements OnInit {
  
  InfoClient:any = [];
  NameClient:string="";
  TicketNumber:string="";
  EmployeeName:string="";
  ArrayListClient: any = [];
  ParamShowClient:any = {};
  
  interval:any;

  constructor(private router:Router, public http:WebserviceService) {
  
    this.http.isStatusClientChanged.subscribe( value => {
      if (value == true){
        this.getInfo();
      }
    });

  }

  ngOnInit() {
    if (sessionStorage.getItem('IdentityName') == '' || sessionStorage.getItem('IdentityName')== null)
    {
      this.router.navigate(['/login']);
    }
    
    this.getInfo();
    this.interval = setInterval(() => { 
        this.getInfo();
    }, 5000);
  }

  getInfo(){
    this.http.ServerGet(this.http.root + "StartTicket")
    .then(data => {
      this.NameClient = data["data"][0]["NameClient"];
      this.TicketNumber = data["data"][0]["TicketNumber"];
      this.EmployeeName = data["data"][0]["EmployeeName"];
      
      this.ShowGridTicket();
      
    }).catch(error => {
      //console.log("error sending Client");
    });
  }

  ShowGridTicket(){
    this.ParamShowClient["TicketNumber"] = this.TicketNumber;
    this.http.ServerPost(this.http.root + "ListWaitingList", this.ParamShowClient)
    .then(data => {
      this.ArrayListClient = data["data"];
    }).catch(error => {
      console.log("error sending Client");
    });
  }

}
