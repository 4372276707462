import { Component, OnInit, TemplateRef, Renderer, ViewContainerRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { navItems } from '../../views/immigration/_nav';

import { NgForm, FormControl } from '@angular/forms';
import { WebserviceService } from '../../webservice.service';

import * as moment from 'moment';

@Component({
  selector: 'app-immigration',
  templateUrl: './immigration.component.html',
  styleUrls: ['./immigration.component.scss']
})
export class ImmigrationComponent implements OnInit {

  public navItems = navItems;

  personal: any = [];
  IdPrep:number = 47;
  FilterIdPre:number=47;
  IdClient:number = 0;
  TypeClient:number = 2;
  IdTicket:number = 0;
  InfoClient:any = [
    {
      TaxPayerName:"", LastName:"", FirstName:"", MiddleInitial:"", EMail:"", DocumentId:"", cStatus:2, City:"", cState:0, ZipCode:"", BirthDate:"", cSource:0, Comment:"", Sex:1, LanguagePreferred:1, StreetAddress:"", Phone:"", cMovil:"", OtherPhone:"",  TaxpayerId:"000-00-0000"
    }
  ];
  InfoFilter:any = [
    {
      FilterStatusClient:0, FilterCreatedClient:"", FilterIdPre:47, SexImmigServ:0
    }
  ]
  ParamShowClient:any = {};
  ArrayComments: any = [];
  ArrayFindClient: any = [];
  DatilComm:string;
  DateComm:string;
  CodComment:number=0;
  ParamS:any={};
  ArrayGetFilter:any={};
  ResultFil:string="";
  ShowResultFilter = false;
  ValueFromFilter:number=0;
  ValueToFilter:number=0;
  CountArrayList:number=0;
  IsLimitIndexFilter:boolean=false;
  LimitCounterFilter:number=2;

  @ViewChild('tFilterClient') private tFilterClient;
  constructor() { }

  ngOnInit() {
  }

}
