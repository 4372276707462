import { Component, OnInit, TemplateRef, Renderer, ViewContainerRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { navItems } from '../../views/user/_nav';

import { NgForm, FormControl } from '@angular/forms';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ToastrManager } from 'ng6-toastr-notifications';

import * as moment from 'moment';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  public navItems = navItems;
  modalRef: BsModalRef;
  ParamS:any = {};
  jsonData:any={};
  EmpId:number=0;
  EmpName:string='';
  EmpPosition:number=0;
  EmpGender:number=0;
  EmpActive:boolean=false;
  EmpUserName:string='';
  EmpPass:string='1234';
  EmpFullName:string='';
  EmpShowInactive:number=0;
  EmpPositionName:string='';
  EmpGenderF:boolean=false;
  EmpGenderM:boolean=false;
  EmpGenderL:boolean=false;
  EmpDirId:number=1;

  constructor() { }

  ngOnInit() {
  }

}
