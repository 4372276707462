import { WebserviceService } from '../../webservice.service';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';

interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

//let http = 'http://localhost:4200/#/'; //new WebserviceService();
let xhttp = new WebserviceService(null,null);
let yhttp = xhttp.getUrl();
let http = 'http://' + yhttp + '/#/'; //xhttp.root;

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fa fa-home fa-fw'
  },
  {
    name: 'Save',
    url: http + 'immigration?method=save',
    icon: 'fa fa fa-floppy-o fa-fw'
  },
  {
    name: 'Clear',
    url: http + 'immigration?method=clear',
    icon: 'fa fa-eraser fa-fw'
  },
  {
    name: 'Filter',
    url: http + 'immigration?method=filter',
    icon: 'fa fa-filter fa-fw'
  },
  {
    name: 'Get All',
    url: http + 'immigration?method=getall',
    icon: 'fa fa-group fa-fw'
  },
  {
    name: 'Next',
    url: http + 'immigration?method=next',
    icon: 'fa fa-share fa-fw'
  },
  {
    name: 'Previous',
    url: http + 'immigration?method=previous',
    icon: 'fa fa-reply fa-fw'
  },
  {
    name: 'Meetings',
    url: http + 'immigration?method=meeting',
    icon: 'fa fa-calendar fa-fw'
  }
];
