import { Component, OnInit, TemplateRef, Renderer, ViewContainerRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { navItems } from '../../views/security/_nav';

import { NgForm, FormControl } from '@angular/forms';
import { WebserviceService } from '../../webservice.service';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ToastrManager } from 'ng6-toastr-notifications';

import * as moment from 'moment';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {

  public navItems = navItems;

  ArrayPermission:any=[];
  personal: any = [];
  IdPrep:number = 0;
  ParamS:any = {};
  SelectAllPermision:any;

  constructor(private router:Router,
    public http:WebserviceService, private modalService: BsModalService, public toastr: ToastrManager, private activatedRoute: ActivatedRoute) { 

    	activatedRoute.queryParams.subscribe
	    (
	      (params:Params)=>{
	        var count = Object.keys(params).length;
	        if (count > 0){
	          this.ExecuteActiveRoute(params['method']);
	        }
	      }
	    );

    }

	ngOnInit() {
	    if (sessionStorage.getItem('IdentityName') == '' || sessionStorage.getItem('IdentityName')== null)
	    {
	      this.router.navigate(['/login']);
	    }
	  	this.getAllpreparer();
	}

	getAllpreparer(){
	  	this.http.getPreparer(this.http.root + "frm_employees")
	  	.then(data => {
	  		if (data["hasdata"] == true){
	  			this.personal = data["data"];
	  		}else {
	  			alert("No hay preppares");
	  		}
	  	});
	}

	LlenaListaPermisos(wUser){
	  	this.ParamS["wUserCode"]=wUser;
	  	this.http.ServerPost(this.http.root + "PermissionList", this.ParamS)
	  	.then(data => {
	  		if (data["hasdata"] == true){
	  			this.ArrayPermission = data["data"];
	  		}else {
	  			alert("No hay permission");
	  		}
	  	});
	}

	UpdatePermissionUser(){
	  	if (this.ParamS["wUserCode"] != 47){
	  		//console.log("user: " + this.ParamS["wUserCode"]);
	  		//console.log("Module: " + data.Codigo);
	  		//console.log("Opc: " + data.Autorizado);
	  		console.log(this.ArrayPermission[0]["Codigo"]);
	  		console.log(this.ArrayPermission[0]["Autorizado"]);
	  	}else{console.log("hola mundo");}
	}

	ExecuteActiveRoute(wMethod){

	    switch (wMethod){
	      case ('update'):
	      	this.UpdatePermissionUser();
	        break;
	    }
	}

}
