import { Component, OnInit, TemplateRef, Renderer, ViewContainerRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import {map, startWith} from 'rxjs/operators';

import { navItems } from '../../views/taxclient/_nav';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ToastrManager } from 'ng6-toastr-notifications';
import { WebserviceService } from '../../webservice.service';

import { FileUploader } from 'ng2-file-upload';

import { FormsModule, NgForm } from '@angular/forms';

import * as moment from 'moment';

import * as $ from 'jquery/dist/jquery.min.js';


@Component({
  selector: 'app-taxclient',
  templateUrl: './taxclient.component.html',
  styleUrls: ['./taxclient.component.scss']
})
export class TaxclientComponent implements OnInit {

  
  public navItems = navItems;
  
  modalRef: BsModalRef;
  modalRef2: BsModalRef;

  StatusList: any = [];
  VirtualStatusList: any = [];
  personal: any = [];
  //InfoClient: any = [];
  ParamShowClient:any = {};
  ClientName:string="";
  CodClient:number = 0;
  IdPrep:number = 47;
  ArrayComments: any = [];
  DatilComm:string;
  DateComm:string;
  CodComment:number=0;
  ArrayFindClient:any = [];
  InfoClient:any = [
    {
      TaxPayerName:"", LastName:"", FirstName:"", MiddleInitial:"", EMail:"", DocumentId:"", ClientStatusID:0, City:"", cState:0, ZipCode:"", cSource:0, Comment:"", Sex:1, LanguagePreferred:1, StreetAddress:"", Phone:"", cMovil:"", OtherPhone:"",  TaxpayerId:"", NameTaxPreparer2015:"", VirtualClientStatusID:0, PermanentPreparer:47,
      BirthDate:moment("01/01/1900").format("YYYY-MM-DD"), IdClient:0
    }
  ];
  InfoNewClient:any = [
    {
      IdClient:0, TaxpayerId:"", LastName:"", FirstName:"", MiddleInitial:"", Phone:"", cMovil:"", OtherPhone:"", 
      EMail:"", City:"", cState:0, ZipCode:"", StreetAddress:"", Comment:"", Sex:1, LanguagePreferred:1, 
      ClientStatusID:0, cSource:0, BirthDate:moment("01/01/1900").format("YYYY-MM-DD")
    }
  ];
  TypeClient:number=1;
  IdTicket:number=0;
  ListBinWorkFlow:any = [];
  ArrayDate:any = [];
  FilterInfo:any;
  CountToFilter:number = 0;
  CountFromFilter:number=1;
  ParamsFilter:any={};
  ParamS:any = {};
  v_Client:number=0;
  UserPass:string=localStorage.getItem('password');
  Proc_Reception:any = [
    {
      proc1:0,proc2:0,proc3:0,proc4:0,proc5:0,proc6:0,proc7:0,proc8:0,proc9:0,proc10:0,
      proc11:0,proc12:0,proc13:0,proc14:0,proc15:0,proc16:0
    }
  ];
  mySource:any=[];
  DateToday:any;

  reportServer: string = ''; 
  reportUrl: string = ''; 
  showParameters: string = "false"; 
  language: string = "en-us";
  width: number = 100;
  height: number = 500;
  toolbar: string = "true";
  DivTemplateRef:TemplateRef<any>;
  //socket: SocketIOClient.Socket;
  smsFrom:string='';
  smsBody:string='';
  smsMMSReceived:string='';
  smsContactsArray:any=[];
  smsContactsArrayCamp:any=[];
  smsNameContactActive:string='';
  smsContentArray:any=[];
  smsIdContactActive:number=0;
  smsContactFirstName:string='';
  smsContactLastName:string='';
  smsContactPhone:string='';
  smsIsNewContact:boolean=false;
  smsListCampaign:any=[];
  smsSaveSuccesCampaign:boolean=false;
  smsCampaignComm:string='';
  smsCampaignDate:any;
  smsCampaignHour:number=1;
  smsCampaignUrlMMS:string='';
  smsCampaignName:string='';
  smsLastDate:string='';
  smsArrayContacts:any=[];
  keyword = 'smscontact';

  countPostBack:number=0;
  canCont:number=0;

  
  public uploader= new FileUploader({url: this.http.root + 'uploadfile',
      itemAlias: 'avatar'});
  hasBaseDropZoneOver:boolean = false;
  hasAnotherDropZoneOver:boolean = false;
  NoteFileUpload:string="";

  @ViewChild('divCreateClient') private divCreateClient;
  @ViewChild('divAdditionalData') private divAdditionalData;
  @ViewChild('divFilterCliente') private divFilterCliente;
  @ViewChild('ReportViewer') private ReportViewer;
  @ViewChild('fcontainer') private fcontainer;
  @ViewChild('DivSms') private DivSms;
  @ViewChild('DivSmsSaveContact') private DivSmsSaveContact;
  @ViewChild('DivSMSConfig') private DivSMSConfig;
  @ViewChild('uploadfileMMS') private uploadfileMMS;
  @ViewChild('FindingClient') private FindingClient;
  @ViewChild('smscontacts') private smscontacts;


  constructor(private router:Router,public http:WebserviceService, private activatedRoute: ActivatedRoute, private modalService: BsModalService, public toastr: ToastrManager) {
  	activatedRoute.queryParams.subscribe
  	(
  		(params:Params)=>{
  			var count = Object.keys(params).length;
        if (count > 0){
          if (this.countPostBack == 0){
            this.ExecuteActiveRoute(params['method']);
          }
        }
  	  }
  	);
  }

  ngOnInit() {
    if (sessionStorage.getItem('IdentityName') == '' || sessionStorage.getItem('IdentityName')== null)
    {
      this.router.navigate(['/login']);
    }

    this.getStatusList();
    this.getVirtualStatusList();
    this.getAllpreparer();
    //this.FindSelectClient();
    this.getListBinWorkFlow();
    this.ParamShowClient["IdClient"] = 0;
    this.ShowClient();
    //setTimeout(()=>{ this.AutoCompleteComment(); }, 5000);
    this.AutoCompleteComment();
    this.DateComm = moment().format("YYYY-MM-DD");
    this.smsSearchContact();

    this.http.onNewSMS().subscribe(data => {
      this.smsFrom = data.From;
      this.smsBody = data.Body;
      this.smsMMSReceived = data.smsMMSReceived;
      if (data == "newsms"){
        this.ProcesNewSMS();
      }
    });

    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = (response:any) => {
      this.showSuccess("All your files was sent!");
      this.smsSendMMS(this.http.root + 'uploads/' + response.file["name"]);
    };
    this.uploader.onBeforeUploadItem = (item) => {
      this.uploader.setOptions({
        additionalParameter: { noteFileUpload: this.NoteFileUpload }
      });
    }

  }

  getStatusList(){
    this.http.ServerGet(this.http.root + "listclientstatus")
    .then(data => {
      if (data["hasdata"] == true){
        this.StatusList = data["data"];
      }else {
        alert("no hay data");
      }
    });
  }

  getVirtualStatusList(){
    this.http.ServerGet(this.http.root + "listvirtualstatus")
    .then(data => {
      if (data["hasdata"] == true){
        this.VirtualStatusList = data["data"];
      }else {
        alert("no hay data");
      }
    });
  }

  getAllpreparer(){
    this.http.ServerGet(this.http.root + "frm_employees")
    .then(data => {
      if (data["hasdata"] == true){
        this.personal = data["data"];
      }else {
        alert("no hay data");
      }
    });
  }

  getListBinWorkFlow(){
    this.http.ServerGet(this.http.root + "ListBinWorkFlow")
    .then(data => {
      if (data["hasdata"] == true){
        this.ListBinWorkFlow = data["data"];
      }else {
        alert("no hay data");
      }
    });
  }

  ShowClient(){
    this.http.ServerPost(this.http.root + "show_client", this.ParamShowClient)
    .then(data => {
      if (data["hasdata"] == true){
        this.InfoClient = data["data"];
        this.ClientName = data["data"][0]["LastName"] + ', ' + data["data"][0]["FirstName"];
        this.FillComments();
      }else {
        //this.ParamShowClient["IdClient"] = 0;
        //alert("no hay data to show client");
      }
    });
  }

  FindSelectClient(){
    
    this.ShowClient();
  } 

  AlertWarning(msg){

    this.toastr.warningToastr(msg, 'Alert', {toastLife: 5000, showCloseButton:true});
  }

  AlertError(msg) {

    this.toastr.errorToastr(msg, 'Error', {toastLife: 5000, showCloseButton:true});
  } 

  showSuccess(msg) {

    this.toastr.successToastr(msg, 'Success!', {toastLife: 2000, showCloseButton:true});
  }

  updateClient(f: NgForm){
    if(f.value.chkRequestFileCabnet == ""){
      f.value.chkRequestFileCabnet = 0;
    }
    if(f.value.chkRequestFileCabnet == false){
      f.value.chkRequestFileCabnet = 0;
    }else if(f.value.chkRequestFileCabnet == true){
      f.value.chkRequestFileCabnet = 1;
    }

    if(f.value.chkDrakeTake == ""){
      f.value.chkDrakeTake = 0;
    }
    if(f.value.chkDrakeTake == false){
      f.value.chkDrakeTake = 0;
    }else if(f.value.chkDrakeTake == true){
      f.value.chkDrakeTake = 1;
    }

    if(f.value.chkEmailedFileCabnet == false){
      f.value.chkEmailedFileCabnet = 0;
    }else if(f.value.chkEmailedFileCabnet == true){
      f.value.chkEmailedFileCabnet = 1;
    }

    if(f.value.chkOutStateClient == ""){
      f.value.chkOutStateClient = 0
    }
    if(f.value.chkOutStateClient == false){
      f.value.chkOutStateClient = 0;
    }else if(f.value.chkOutStateClient == true){
      f.value.chkOutStateClient = 1;
    }
    this.http.ServerPost(this.http.root + "updatetaxclient", f.value)
    .then(data => {
      if (data["hasdata"] == true){
        this.CodClient = data["data"][0]["@MaxCod"];
        this.showSuccess('Info Saved!!');
      }else {
        alert("no hay data");
      }
    });
  }

  FillComments(){
    let yy = new Date();
    this.ParamShowClient["hClient"] = this.CodClient;
    this.ParamShowClient["SourceID"] = 1;

    this.http.ServerPost(this.http.root + "ShowClientComment", this.ParamShowClient)
    .then(data => {
      this.ArrayComments = data["data"];
      if (data["data"][0]["Msg"] == 1){
        $("#DivCallClient").fadeIn();
      }
    }).catch(error => {
      console.log(error);
    });
  }

  editComment(idComment){
    this.ParamShowClient["CommentID"] = idComment;
    this.http.ServerPost(this.http.root + "FillEditComment", this.ParamShowClient)
    .then(data => {
      this.IdPrep = data["data"][0]["EmployeeID"];
      this.DatilComm = data["data"][0]["Comments"];
      this.DateComm = moment(data["data"][0]["DateComment"]).format("YYYY-MM-DD");
      this.CodComment = idComment;
    }).catch(error => {
      console.log("error sending Client");
    });
  }

  submitComment(f: NgForm){
    f.value.IdClientComm = this.CodClient;
    f.value.comDate = moment(f.value.comDate).format("YYYY-MM-DD");
    if (f.value.CodComment == 0){
      f.value.commentDatailComment = f.value.inputDatailComment.comments;
    }else{
      f.value.commentDatailComment = f.value.inputDatailComment;
    }
    this.http.ServerPut(this.http.root + "RecordDatailComm", f.value)
    
    this.ShowClient();

    this.showSuccess('Info Saved!!');

    this.IdPrep = 47;
    this.DateComm = moment().format("YYYY-MM-DD");
    this.DatilComm = '';

    setTimeout(()=>{ 

      this.showResultFilter(this.CountToFilter);

      $("#lblCountFromFilter").val(this.CountFromFilter);


     }, 1000);
  }

  openModal(divCreateClient: TemplateRef<any>) {

    this.modalRef = this.modalService.show(divCreateClient, {class: 'modal-xl', backdrop: 'static'});
  }

  openModalLarge(divCreateClient: TemplateRef<any>) {

    this.modalRef = this.modalService.show(divCreateClient, {class: 'modal-lg', backdrop: 'static'});
  }

  IntroSSN(){
    let ssn;
    ssn = $("#fSocialSecurity").val();
    this.ParamShowClient["ClientSSN"] = ssn;
    this.ParamShowClient["IdClient"] = 0;
    this.FindAllClient();
  }

  FindAllClient(){
    this.http.ServerPost(this.http.root + "show_client", this.ParamShowClient)
    .then(data => {
      if (data["hasdata"] == true){
        this.CodClient = data["data"][0]["IdClient"];
        this.ArrayFindClient = data["data"];
      }else {
        alert("no data");
      }
    });
  }

  ApplyFilterClient(f: NgForm){
    if (f.value.FilterchkDeniedEIC == ""){
      f.value.FilterchkDeniedEIC = 0;
    }
    if (f.value.FilterchkImmigration == ""){
      f.value.FilterchkImmigration = 0;
    }
    if (f.value.FilterchkInterestedServ == ""){
      f.value.FilterchkInterestedServ = 0;
    }
    if (f.value.FilterchkNotCall == ""){
      f.value.FilterchkNotCall = 0;
    }
    if (f.value.FilterchkSchC == ""){
      f.value.FilterchkSchC = 0;
    }
    if (f.value.optionsRadiosCalls == ""){
      f.value.optionsRadiosCalls = 0;
    }
    //this variable allow reuse all parameter for doing next and previos event
    this.ParamsFilter = f.value;
    this.http.ServerPost(this.http.root + "ApplyFilterClient", f.value)
    .then(data => {
      if (data["hasdata"] == true){
        if (data["data"][0]["idclient"] > 0){
          this.FilterInfo = data["data"];
          this.CodClient = data["data"][0]["idclient"];
          this.CountToFilter = data["data"].length;
          this.ParamShowClient["IdClient"] = data["data"][0]["idclient"];

          this.ShowClient();

          setTimeout(()=>{ this.showResultFilter(this.CountToFilter) }, 1000);
        }else{
          $("#ResultFilter").val('No Results Found');
          $("#ResultFilter").css({ "color": "red" });
          $("#ResultFilter").fadeIn();
          $("#DivStatusFilter").fadeOut();
        }
        

      }else {
        $("#ResultFilter").val('No Data Found');
      }
    });
    //console.log(f.value);
  }

  showResultFilter(CountToFilter){
    $("#ResultFilter").css({ "color": "red" });
    $("#lblCountToFilter").val(CountToFilter);

    //$("#lblCountFromFilter").val(CountToFilter);

    $("#lblCountFromFilter").val(1);
    $("#ResultFilter").val(' have result !!');
    $("#ResultFilter").fadeIn();
    $("#DivStatusFilter").fadeIn();
  }

  SubmitFind(IdClient){
    this.ParamShowClient["IdClient"] = IdClient;
    this.ShowClient();
  }

  async SaveClient(f: NgForm) {  
    var res;
    res = await this.ItCanSaveClient(f);
    if (!res){
      return;
    }
    f.value.DOBCustomer = moment(f.value.DOBCustomer).format("YYYY-MM-DD");

    this.http.clientput(this.http.root + "save_client", f.value)
    .then(data => {
      this.CodClient = data["data"][0]["@MaxCod"];
      this.showSuccess('Info Saved!!');
    }).catch(error => {
      console.log(error);
    });
    this.modalRef.hide();    
  }

  async ItCanSaveClient(f: NgForm){
    let ssc;
    var cc;
    if (f.controls.SocialSecutiyCustomer.valid == false){
      this.AlertWarning("SSN Invalid");
      return false;
    }
    if (f.controls.LastNameCustomer.valid == false){
      this.AlertWarning("Last Name Invalid");
      return false;
    }
    if (f.controls.FirstNameCustomer.valid == false){
      this.AlertWarning("First Name Invalid");
      return false;
    }
    if (f.value.SecCode.length == 0){
      this.AlertWarning("Username Password Missing");
      return false;
    }
    if (this.UserPass != f.value.SecCode){
      //console.log(localStorage.getItem('SecurityCode') + " fcod: " + f.value.SecCode);
      this.AlertWarning("Username Password Missing");
      return false;
    }

    ssc = f.controls.SocialSecutiyCustomer;
    cc = await this.VerifyRegistedClient(ssc);

    Observable.forkJoin([cc]);

    if(this.v_Client>0){
      return false;
    }else{return true;}
  }

  createRange(){
    var Dates = new Date();
    var items = [];
    for(var i = 2015; i <= Dates.getFullYear() - 2; i++){
       items.push(i);
    }

    return items;
  }

  GoNextClient() {
    let countFrom = this.CountFromFilter;
    if (parseInt($("#lblCountFromFilter").val()) >= 1 && parseInt($("#lblCountFromFilter").val()) < parseInt($("#lblCountToFilter").val())){
      var IdCl;
    var filteredValue;
    this.CountFromFilter += 1;
    this.ParamsFilter["RowIndex"] = this.CountFromFilter;
    this.http.ServerPost(this.http.root + "ApplyFilterClient", this.ParamsFilter)
    .then(data => {
      if (data["hasdata"] == true){
        this.ParamShowClient["IdClient"] = data["data"][countFrom]["idclient"];
        this.CodClient = data["data"][countFrom]["idclient"];
        this.ShowClient();
      }
    });

    setTimeout(()=>{ 

      this.showResultFilter(this.CountToFilter);

      $("#lblCountFromFilter").val(this.CountFromFilter);


     }, 1000);
    }else{
      this.AlertWarning("You've reached the end of the list");
    }
  }

  GoPreviosClient(){
    let countFrom = this.CountFromFilter;
    if (parseInt($("#lblCountFromFilter").val()) > 1 && parseInt($("#lblCountFromFilter").val()) <= parseInt($("#lblCountToFilter").val())){
      var IdCl;
    var filteredValue;

    this.CountFromFilter -= 1;

    //var lblCounterFromFilter = parseInt($("#lblCountFromFilter").val()) - 1;

    //IdCl = this.FilterInfo[lblCounterFromFilter]["IdClient"];

    this.ParamsFilter["RowIndex"] = this.CountFromFilter;
    this.http.ServerPost(this.http.root + "ApplyFilterClient", this.ParamsFilter)
    .then(data => {
      if (data["hasdata"] == true){
        this.ParamShowClient["IdClient"] = data["data"][countFrom]["idclient"];
        this.CodClient = data["data"][countFrom]["idclient"];
        this.ShowClient();
      }
    });

    setTimeout(()=>{ 

      this.showResultFilter(this.CountToFilter);

      $("#lblCountFromFilter").val(this.CountFromFilter);


     }, 1000);
    }else{
      this.AlertWarning("You've reached the end of the list");
    }
    
  }

  VerifySecurity(Md){
    this.ParamS["wUserName"] = sessionStorage.getItem('IdentityName');
    this.ParamS["wModule"] = Md;
    this.http.ServerPost(this.http.root + "VerifySecurity", this.ParamS)
    .then(data => {
      if (data["data"][0]["Allow"] == 0){
        this.AlertWarning("Access Denied");
        return;
      }
      switch (Md){
        case 5:
          this.openModal(this.divCreateClient);
           return;
        case 34:
          this.openModal(this.divAdditionalData);
           return;
        case 10:
          this.openModal(this.divFilterCliente);
           return;
        case 11:
          this.updateClient(this.fcontainer);
           return;
        case 12:
          this.updateClient(this.fcontainer);
           return;
        case 31:
          this.updateClient(this.fcontainer);
           return;
        case 13:
          this.updateClient(this.fcontainer);
           return;
        case 14:
          this.updateClient(this.fcontainer);
           return;
        case 15:
          this.updateClient(this.fcontainer);
           return;
      }
      //console.log(Md);
      
    }).catch(error => {
      console.log("error sending: " + error);
    });
  }

  async VerifyRegistedClient(wIdClient){
    this.ParamS["SSN"] = wIdClient.value;
    await this.http.FillListTicketsReception(this.http.root + "VerifyRegistedClient", this.ParamS)
    .then(data => {
      if (data["data"][0]["@IdClient"]>0){
        this.AlertWarning("this SSN is used by another client");
        this.v_Client= data["data"][0]["@IdClient"];
      }else{
        this.v_Client=0;
      }
    });
  }  

  ClearDataClient(){
    this.InfoClient[0].LastName = "";
    this.InfoClient[0].FirstName = "";
    this.InfoClient[0].MiddleInitial = "";
    this.InfoClient[0].EMail = "";
    this.InfoClient[0].cStatus = 2;
    this.InfoClient[0].City = "";
    this.InfoClient[0].cState = 0;
    this.InfoClient[0].ZipCode = "";
    this.InfoClient[0].BirthDate = moment('1900-01-01').format("YYYY-MM-DD");
    this.InfoClient[0].cSource = 0;
    this.InfoClient[0].Comment = "";
    this.InfoClient[0].Sex = 1;
    this.InfoClient[0].LanguagePreferred = 1;
    this.InfoClient[0].StreetAddress = "";
    this.InfoClient[0].Phone = "";
    this.InfoClient[0].cMovil = "";
    this.InfoClient[0].OtherPhone = "";
    this.InfoClient[0].TaxpayerId = "";
    this.Proc_Reception = [
      {
        proc1:0,proc2:0,proc3:0,proc4:0,proc5:0,proc6:0,proc7:0,proc8:0,proc9:0,proc10:0,
        proc11:0,proc12:0,proc13:0,proc14:0,proc15:0,proc16:0
      }
    ];

  }

  GetProcRec(){
    var d = new Date();
    var n = d.getFullYear() - 1;
    this.ParamS["TaxYear"] = n;
    this.ParamS["IdClient"] = this.CodClient;
    this.http.ServerPost(this.http.root + "get_ProcRec", this.ParamS)
    .then(data => {
      if (data["hasdata"]==true){
        this.Proc_Reception = data["data"];
        //console.log("Parametros: " + this.ParamS["IdClient"] + " y " + this.ParamS["TaxYear"]);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  AutoCompleteComment(){
    this.ParamS["strComment"] = '';

    this.http.ServerPost(this.http.root + "SerchComments",this.ParamS)
    .then(data => {
      if (data["hasdata"]==true){
        this.mySource = data["data"];
        //element1.focus();
        //console.log(this.mySource);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  ShowModalReport(){
    this.openModal(this.ReportViewer);
    }

  ShowModalSMS(){
    this.smsContentArray = [];
    this.smsNameContactActive='';
    this.smsGetAllContacts(null);
    this.smsIdContactActive = 0;

    setTimeout(()=>{
       this.openModalLarge(this.DivSms);
    },1000);
  }

  OpenModal(wDivModal){
    $("#BackgroundModalPopup").css({ "opacity": "0.5" });
    $("#BackgroundModalPopup").fadeIn();
    $("#" + wDivModal).fadeIn();
  }

  ComingSms(smsFrom, smsBody){
    $('<li style="display: inline-block;clear: both;float: left;margin: 15px 15px 5px 15px;width: calc(100% - 25px);font-size: 0.9em;"><img src="https://apl.finagro.com.co/infoagro/Resources/Images/usuarios.png" alt="" style="width: 22px;border-radius: 50%;float: left;" /><p style="background: #435f7a;color: #f5f5f5;display: inline-block;padding: 10px 15px;border-radius: 20px;">' + smsBody + '</p></li>').appendTo($('.messages ul'));
    
    //$(".messages").animate({ scrollTop: $(document).height() }, "fast");
  }

  SendingSms(){
    let message = $(".message-input input").val();

    if (this.smsIdContactActive == 0){
      this.AlertWarning("Select Client");
      return false;
    }
    if($.trim(message) == '') {
      this.AlertWarning("Write your message");
      return false;
    }

    this.ParamS["SmsString"] = message;
    this.ParamS["wIdContact"] = this.smsIdContactActive;
    this.ParamS["smsTo"]=this.smsContactPhone;
console.log(this.ParamS);
    this.http.ServerPost(this.http.root + "sendingsms", this.ParamS)
    .then(data =>{
    }).catch(error => {
      console.log(error);
    });
    
    this.smsContentArray.push({"smstxtsend":message});
    
    $(".message-input input").val('');
  }

  smsGetAllContacts(IdCont){
    if (IdCont == 0){
      this.smsCampaignName = '';
      this.smsCampaignComm = '';
      this.smsCampaignDate='';
      this.smsCampaignHour= 1;
      this.smsCampaignUrlMMS= '';

      return;
    }
    //else{this.smsCampaignName = wNameCampaign;}
    
    this.ParamS["xidcont"] = IdCont;
    this.smsContactsArrayCamp = [];
    this.smsContactsArray = [];
    let ArrayContact = [];

    this.http.ServerPost(this.http.root + "smsGetAllContacts", this.ParamS)
    .then(data => {
      this.smsContactsArray = data["data"];
      setTimeout(()=>{
        ArrayContact = this.smsContactsArray.filter(function(id){

          return id.HasUnread == 1;
        });
        if (ArrayContact.length == 0){
          this.http.NewSmsComing.next(0);
        }
      },1000);
    });

  }

  smsGetCampaign(wIdCamp){
    this.ParamS["wIdCamp"] = wIdCamp;
    this.http.ServerPost(this.http.root + "smsGetCampaign", this.ParamS)
    .then(data => {
      if (data["hasdata"] == true){
        this.smsCampaignName = data["data"][0]['namecampaign'];
        this.smsCampaignComm = data["data"][0]['smscampaigntext'];
          this.smsCampaignDate=moment(data["data"][0]['datecampaign']).format("YYYY-MM-DD");
      
        this.smsCampaignHour= data["data"][0]['idhourcampaign'];
        this.smsCampaignUrlMMS= data["data"][0]['mmscampaign'];

        this.smsGetContactsCampaign(wIdCamp);
      }
      else
      {
        this.smsCampaignComm='';
        this.smsCampaignDate='';
        this.smsCampaignHour=1;
        this.smsCampaignUrlMMS='';
        this.smsCampaignName='';
        this.smsGetContactsCampaign(0);
      }
    });
  }

  smsGetContactsCampaign(wIdCamp){
    this.ParamS["wIdCamp"] = wIdCamp;
    this.http.ServerPost(this.http.root + "smsGetContactsCampaign", this.ParamS)
    .then(data =>{
      if (data["hasdata"]==true){
        this.smsContactsArrayCamp = data["data"];
      }
    });
  }

  smsGetContent(nameContact, IdsmsContact){
    var smsDate="";
    var ArrayContact = [];
    this.smsContentArray = [];
    this.ParamS["wIdContact"] = IdsmsContact;
    this.smsNameContactActive = nameContact;
    this.smsIdContactActive = IdsmsContact;
    this.http.ServerPost(this.http.root + "smsGetAllMessageByContact", this.ParamS)
    .then(data => {
      smsDate = data["data"][0]["dateformated"]
     
      this.smsContentArray = data["data"];
      ArrayContact = data["data"];

      try{
        ArrayContact.forEach(function(xDate, index, arr){
          if (smsDate == ArrayContact[index + 1].dateformated){
           smsDate = ArrayContact[index + 1].dateformated;
           ArrayContact[index + 1].dateformated = "";
          }
          else {
           smsDate = ArrayContact[index + 1].dateformated;
          }
       });
      }catch(error){
        console.log(error);
      }

      // this.smsContentArray = ArrayContact;

      // ArrayContact = this.smsContactsArray.filter(function(id){
      //   return id.idsmscontact == IdsmsContact;
      // });

      // ArrayContact[0]["HasUnread"] = 0;
      // this.smsContactPhone = ArrayContact[0]["smsmovilphone"];
    });

    //$(".messages").animate({ scrollTop: $(document).height() }, "fast");

     this.smsSaveSms();
    // setTimeout(()=>{
    //   this.smsGetAllContacts(0);
    // },1000);
    this.smsContactPhone = nameContact.slice(-12);
  }

  ShowModalSaveContact(IsNew){
    let ArrayContact = [];
    let idContact = this.smsIdContactActive;
    let myPhoneNumber;

    this.DivTemplateRef = this.DivSmsSaveContact;

    ArrayContact = this.smsContactsArray.filter(function(id){
      return id.idsmscontact == idContact;
    });

    if (IsNew == 1){
      this.smsContactFirstName = '';
      this.smsContactLastName = '';
      this.smsContactPhone = '';
      this.smsIsNewContact = true;
    }
    else{
      this.smsIsNewContact = false;
      this.smsContactFirstName = ArrayContact[0]["smsfirstname"];
      this.smsContactLastName = ArrayContact[0]["smslastname"];
      myPhoneNumber = ArrayContact[0]["smsmovilphone"];
      myPhoneNumber = myPhoneNumber.replace('+1','');
      this.smsContactPhone = this.formatPhoneNumber(myPhoneNumber);
    }
    this.modalRef2 = this.modalService.show(this.DivTemplateRef, {class: 'modal-sm', backdrop: 'static'});
  }

  smsSaveContact(){
    let ArrayContact = [];
    let smsPhoneContact;
    let myPhoneNumber = this.smsContactPhone;

    if (this.smsContactPhone == '' || this.smsContactPhone == null){
      this.AlertWarning("Info. Not Valid");
      return;
    }
    if (this.smsContactLastName == null || this.smsContactFirstName == null || this.smsContactLastName == '' || this.smsContactFirstName == ''){
      this.AlertWarning("Info. Not Valid");
      return;
    }
    if (this.smsIsNewContact == true){
      this.smsIdContactActive = 0;
    }
    this.ParamS["Wich"] = this.smsIdContactActive;
    myPhoneNumber = myPhoneNumber.replace(/\D+/g, "");
    myPhoneNumber = '+1' + myPhoneNumber
    this.ParamS["Phone"] = myPhoneNumber;
    smsPhoneContact = myPhoneNumber;
    this.smsContactPhone = myPhoneNumber;
    this.ParamS["lastname"] = this.smsContactLastName;
    this.ParamS["firstname"] = this.smsContactFirstName;
    this.http.ServerPost(this.http.root + "saveSmsContact", this.ParamS)
    .then(data => {
      if (data["success"] == true){
        this.showSuccess("Contact Save !!");
        this.smsContactsArray = [];
        this.smsGetAllContacts(null);

        setTimeout(()=>{
          ArrayContact = this.smsContactsArray.filter(function(id){
            return id.smsmovilphone == smsPhoneContact;
          });
          this.smsIdContactActive = ArrayContact[0]["idsmscontact"]
        },1000);     
      }
    });
    this.smsNameContactActive = this.smsContactFirstName + ' ' + this.smsContactLastName;
    this.smsIsNewContact = false;
    this.modalRef2.hide();
  }

  ProcesNewSMS(){
    let ArrayContact = [];
    let idContact;
    let wSmsFrom = this.smsFrom;
    if(this.smsIdContactActive > 0){
      idContact = this.smsIdContactActive;
      ArrayContact = this.smsContactsArray.filter(function(id){
        return id.idsmscontact == idContact;
      });
      this.smsContactPhone = ArrayContact[0]["smsmovilphone"];
      if (this.smsContactPhone == this.smsFrom){
        this.smsContentArray.push({"smstxtreceived":this.smsBody});
        this.smsContentArray.push({"mmsurlreceived":this.smsMMSReceived});
        //setTimeout(()=>{this.http.NewSmsComing.next(0)},1000);
        setTimeout(()=>{this.smsSaveSms()},1000);

        this.http.NewSmsComing.next(0);
        //this.smsSaveSms();

        //$(".messages").animate({ scrollTop: $(document).height() }, "fast");

      }else{
        this.smsGetAllContacts(null);
        setTimeout(()=>{
          ArrayContact = this.smsContactsArray.filter(function(id){
            return id.smsmovilphone == wSmsFrom;
          });
          ArrayContact[0]["HasUnread"] = 1;
        },1000);
      }
    }
    else{
      this.smsGetAllContacts(null);
      setTimeout(()=>{
        ArrayContact = this.smsContactsArray.filter(function(id){
          return id.smsmovilphone == wSmsFrom;
        });
        ArrayContact[0]["HasUnread"] = 1;  
      },1000);
    }
  }

  smsSaveSms(){
    this.ParamS["wIdContact"] = this.smsIdContactActive;
    this.ParamS["wRead"] = 0;
    this.ParamS["wFrom"]='';
    this.http.ServerPost(this.http.root + "smsUpdateSms", this.ParamS);
    //.then(data=>{
      //console.log(data["success"]);
    //}).catch(error => {
      //console.log(error);
    //});
  }

  formatPhoneNumber(phone) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  ShowSMSConfig(){
    this.smsGetAllCampaignName();
    this.smsGetContactsCampaign(0);

    this.smsCampaignComm='';
    this.smsCampaignDate='';
    this.smsCampaignHour=1;
    this.smsCampaignUrlMMS='';
    this.smsCampaignName='';

    //this.smsContactsArrayCamp = this.smsContactsArray;
    this.DivTemplateRef = this.DivSMSConfig;
    this.modalRef2 = this.modalService.show(this.DivTemplateRef,{class: 'modal-lg',backdrop:'static'});
  }

  smsSendMMS(url:string){
    if (this.smsIdContactActive == 0){
      this.AlertWarning("Select Client");
      return;
    }
    if (url.length == 0){
      this.AlertWarning("Write URL to send");
      return;
    }
    let res = url.slice(-3);
    
    //if (res == 'png' || res == 'jpeg' || res == 'gif'){
      this.ParamS["SmsString"] = '';
      this.ParamS["wIdContact"] = this.smsIdContactActive;
      this.ParamS["smsTo"]=this.smsContactPhone;
      this.ParamS["smsUrlMms"]=url;
      
      this.http.ServerPost(this.http.root + "sendingsms", this.ParamS);

      //.then(data =>{
        //console.log(data["data"]);
      //}).catch(error => {
        //console.log(error);
      //});

      this.smsContentArray.push({"mmsurlsent":url});
      this.modalRef2.hide();
    //}
    //else
    //{
      //this.AlertWarning("Image type not valid");
      //return;
    //}
  }

  ShowSmsMMS(){
    if (this.smsIdContactActive == 0){
      this.AlertWarning("Select Client");
      return;
    }
    this.uploader.clearQueue()
    this.DivTemplateRef = this.uploadfileMMS;
    this.modalRef2 = this.modalService.show(this.DivTemplateRef,{class: 'modal-md',backdrop:'static'});
  }

  SaveCampaign(f: NgForm){
    let IdCamp = f.value.selectListCampaign;

    if (this.smsContactsArrayCamp.length == 0){
      this.AlertWarning("There are not client to save");
      return;
    }
    if (IdCamp == 0){
      if (f.value.txtNameCampaign.length == 0){
        this.AlertWarning("Write the name for this campaign");
        return;
      }
    }
    if (IdCamp == 0){
      this.ParamS["wIdCamp"]=0;
    }else{this.ParamS["wIdCamp"]=IdCamp;}

    if (f.value.smsConfigGeneralDate == ''){
      f.value.smsConfigGeneralDate = moment('1900-01-01').format("YYYY-MM-DD");
    }
    this.ParamS["NameCampaign"]=f.value.txtNameCampaign;
    this.ParamS["SmsCampaignText"]=f.value.smsConfigGeneralComment;
    this.ParamS["DateCampaign"]=f.value.smsConfigGeneralDate;
    this.ParamS["HourCampaign"]=f.value.smsConfigGeneralHour;
    this.ParamS["MmsCampaign"]=f.value.smsConfigGeneralMMS;
    this.ParamS["statusCampaign"]=1;

    this.http.ServerPost(this.http.root + "SaveCampaign", this.ParamS)
      .then(data=>{
        if(data["success"]==true){
          IdCamp = data["data"][0]["@MaxCod"];
            this.smsSaveSuccesCampaign = true;
        }else{this.smsSaveSuccesCampaign = false;}
    });


    setTimeout(()=>{
      this.smsContactsArrayCamp.forEach((myObject, index)=>{
        this.ParamS["wIdContact"]=myObject.idsmscontact;
        this.ParamS["wIdCamp"]=IdCamp;
        this.http.ServerPost(this.http.root + "SaveClientCampaign", this.ParamS)
        .then(data=>{
          if(data["success"]==true){
            this.smsSaveSuccesCampaign = true;
          }
        });
      });

      if (this.smsSaveSuccesCampaign == true){
        this.showSuccess("Campaign Saved");
        this.modalRef2.hide();
      }
    },1000);
  }

  smsGetAllCampaignName(){
    this.http.ServerGet(this.http.root + "smsGetListCampaign")
    .then(data => {
      if (data["hasdata"] == true){
        this.smsListCampaign = data["data"];
      }
    });
  }

  smsSearchContact(){
    this.ParamS["wStrContact"] = "";

    this.http.ServerPost(this.http.root + "smsSearchContact", this.ParamS)
    .then(data => {
      if (data["hasdata"] == true){
        this.smsArrayContacts = data["data"];
      }
    });
  }

  selectEvent(item) {
    this.smsGetContent(item.smscontact,item.idsmscontact);
  }

    
  removeSmsContactCampaign(idContact){
    let ArrayContact;
    ArrayContact = this.smsContactsArrayCamp.filter(function(id){
      return id.idsmscontact != idContact;
    });
    this.smsContactsArrayCamp = ArrayContact;
  }

  smsSendNowCampaign(){
    console.log("hola mundo");
  }

  smsSendNowCamapaign(f:NgForm){
    let IsNew = f.value.selectListCampaign;

    if (this.smsContactsArrayCamp.length == 0){
      this.AlertWarning("There are not client to save");
      return;
    }
    if (IsNew == 0){
      if (f.value.txtNameCampaign.length == 0){
        this.AlertWarning("Write the name for this campaign");
        return;
      }
    }

    this.ParamS["SmsString"]=f.value.smsConfigGeneralComment;
    this.ParamS["smsUrlMms"]=f.value.smsConfigGeneralMMS;
    
    this.smsContactsArrayCamp.forEach((myObject, index)=>{
      this.ParamS["smsTo"]=myObject.smsmovilphone;
      this.ParamS["wIdContact"] = myObject.idsmscontact;
      this.http.ServerPost(this.http.root + "sendingsms", this.ParamS);
    });
    this.SaveCampaign(f);
  }

  SendAllUploadFile(){
    this.uploader.uploadAll();
  }

  fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
    //alert("fileOver");
  }
 
  fileOverAnother(e:any):void {
    this.hasAnotherDropZoneOver = e;
    //alert("OverAnother");
  }

  SetCountRouterLink(){
    this.countPostBack = 0;
  }

  ExecuteActiveRoute(wMethod){
    switch (wMethod){
      case ('search'):
        this.openModal(this.FindingClient)
        break;
      case ('photo'):
        //this.ShowFotos();
        break;
      case ('newclient'):
        this.VerifySecurity(5);
        break;
      case ('addclientdata'):
        this.VerifySecurity(34);
        break;
      case ('filter'):
        this.VerifySecurity(10);
        break;
      case ('next'):
        if (this.canCont == 0){
          this.GoNextClient();
        }
        this.canCont = 1;
        this.router.navigate([]);
        setTimeout(()=>{this.canCont = 0;},1000);
        break;
      case ('previous'):
        if (this.canCont == 0){
          this.GoPreviosClient();
        }
        this.canCont = 1;
        this.router.navigate([]);
        setTimeout(()=>{this.canCont = 0;},1000);
        break;
      case ('Reports'):
        this.ShowModalReport();
        break;
      case ('sms'):
        this.ShowModalSMS();
        break;
    }
  }

  xSendingSMSCampaign(){
    var listsms:any;

      listsms = [
        {
            "idcontact": "1",
            "smsphone": "+13478454109"
        },
        {
            "idcontact": "1",
            "smsphone": "+18096612285"
        }
        
        ];

      
    listsms.forEach((item, index) => {
        //this.ParamS["SmsString"] = "Dear Pupilo Income Tax Client, \n \n Thank you for being one of our customers. The best compliment we could receive from you is a referral to a family member, friend, or coworker. Please find attached a referral coupon. For each new paying client you refer to us, you will receive $50 cash as a gift from us. \n \n Sincerely, \n \n The Pupilo Income Tax Team. \n \n Our office is currently open Monday 9:00 am to 6:00 pm. Pupilo Income Tax 206 S. Broadway Yonkers, Ny 10705. E-Mail: info@pupilo.tax Web page: https://pupilo.tax \n \n https://pupilo.tax/coupon-referral";
        this.ParamS["smsUrlMms"]='http://69.87.220.221:3000/uploads/COUPON2023VER2.pdf';      

        setTimeout(()=>{   
            this.ParamS["wIdContact"] = item.idcontact;
            this.ParamS["smsTo"] = item.smsphone;
            
            this.http.ServerPost(this.http.root + "sendingsms", this.ParamS)
            .then(data =>{
                console.log("sms enviado a: " + item.smsphone + " index: " + index)
            }).catch(error => {
                console.log(error);
            });
        },4000 * index);

    });

  }
  

  sendSms(xLen){
    setTimeout(()=>{        
      this.http.ServerPost(this.http.root + "sendingsms", this.ParamS)
      .then(data =>{
        console.log("sms enviado: " + xLen)
      }).catch(error => {
        console.log(error);
      });
    },4000 * xLen); 
  }

}
