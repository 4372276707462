import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

import { WebserviceService } from './webservice.service';
import { AuthguardGuard } from './authguard.guard';

import { WaitinglistComponent } from './views/waitinglist/waitinglist.component';
import { ReceptionComponent } from './views/reception/reception.component';
import { WorkflowComponent } from './views/workflow/workflow.component';
import { TaxclientComponent } from './views/taxclient/taxclient.component';
import { FaxlogComponent } from './views/faxlog/faxlog.component';
import { UserComponent } from './views/user/user.component';
import { SecurityComponent } from './views/security/security.component';
import { ImmigrationComponent } from './views/immigration/immigration.component';

export const routes: Routes = [
  {
    path: '',
    //redirectTo: 'dashboard',
    pathMatch: 'full',
    component: LoginComponent,
    canActivate: [AuthguardGuard]
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'base',
        loadChildren: './views/base/base.module#BaseModule'
      },
      {
        path: 'buttons',
        loadChildren: './views/buttons/buttons.module#ButtonsModule'
      },
      {
        path: 'charts',
        loadChildren: './views/chartjs/chartjs.module#ChartJSModule'
      },
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
        //,canActivate: [AuthguardGuard]
      },
      {
        path: 'icons',
        loadChildren: './views/icons/icons.module#IconsModule'
      },
      {
        path: 'notifications',
        loadChildren: './views/notifications/notifications.module#NotificationsModule'
      },
      {
        path: 'theme',
        loadChildren: './views/theme/theme.module#ThemeModule'
      },
      {
        path: 'widgets',
        loadChildren: './views/widgets/widgets.module#WidgetsModule'
      }
    ],
    canActivate: [AuthguardGuard]
  },
  {
    path:'waitinglist',
    component: WaitinglistComponent,
    canActivate: [AuthguardGuard]
  },
  {
    path:'reception',
    component: ReceptionComponent,
    canActivate: [AuthguardGuard]
  },
  {
    path:'workflow',
    component: WorkflowComponent,
    canActivate: [AuthguardGuard]
  },
  {
    path:'taxclient',
    component: TaxclientComponent,
    canActivate: [AuthguardGuard]
  },
  {
    path:'faxlog',
    component: FaxlogComponent,
    canActivate: [AuthguardGuard]
  },
  {
    path:'user',
    component: UserComponent,
    canActivate: [AuthguardGuard]
  },
  {
    path:'security',
    component: SecurityComponent,
    canActivate: [AuthguardGuard]
  },
  {
    path:'immigration',
    component: ImmigrationComponent,
    canActivate: [AuthguardGuard]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
