import { Component, OnInit, TemplateRef, ViewContainerRef, ViewChild,Injectable } from '@angular/core';
import { Router,ActivatedRoute, Params } from '@angular/router';

import { WebserviceService } from '../../webservice.service';

import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ToastrManager } from 'ng6-toastr-notifications';

import { navItems } from '../../views/reception/_nav';

import * as $ from 'jquery/dist/jquery.min.js';


@Component({
  selector: 'app-reception',
  templateUrl: './reception.component.html',
  styleUrls: ['./reception.component.scss']
})
export class ReceptionComponent implements OnInit {

  public navItems = navItems;

  personal: any = [];
  ArrayListTIcket:any = [];

  ClientName:string="";
  ClientSSN:string="";
  idPrep: any = 47;
  ParamShowClient:any = {};
  InfoClient:any = [
    {
      TaxPayerName:"", LastName:"", FirstName:"", MiddleInitial:"", EMail:"", DocumentId:"", cStatus:2, City:"", cState:0, ZipCode:"", BirthDate:moment('1900-01-01').format("MM-DD-YYYY"), cSource:0, Comment:"", Sex:1, LanguagePreferred:1, StreetAddress:"", Phone:"", cMovil:"", OtherPhone:"",  TaxpayerId:""
    }
  ];

  TicketNumber:string = "";
  IdClient:number = 0;

  modalRef: BsModalRef;
  TypeClient:number = 1;
  IdTicket:number = 0;
  Proc_Reception:any = [
    {
      proc1:0,proc2:0,proc3:0,proc4:0,proc5:0,proc6:0,proc7:0,proc8:0,proc9:0,proc10:0,
      proc11:0,proc12:0,proc13:0,proc14:0,proc15:0,proc16:0,

      proc17:0,proc18:0,proc19:0,proc20:0,proc21:0,proc22:0,proc23:0,proc24:0,proc25:0,proc26:0,

      proc27:0,proc28:0,proc29:0,proc30:0,proc31:0,proc32:0,proc33:0,proc34:0,proc35:0,proc36:0,
      proc37:0,proc38:0,proc39:0,proc40:0, proc41:0, proc42:0, proc43:0, proc44:0, proc45:0,
      proc46:0, proc47:0, proc48:0, proc49:0, proc50:0, proc51:0, proc52:0, proc53:0
    }
  ];
  ParamS:any={};
  ModalBoolean:boolean = false;
  returnValues:boolean=true;
  v_Client:number=0;
  ngSSN:string="";
  UserPass:string=localStorage.getItem('password');
  ArrayFindClient:any=[];
  myDate:any="";

  idprep:number=47;
  idprep2:number=47;
  idprep3:number=47;
  idprep4:number=47;
  datecomm:string= moment().format('L');
  datecomm2:string= moment().format('L');
  datecomm3:string = moment().format('L');
  datecomm4:string = moment().format('L');
  //default mount 20%
  porccomm:number =20;
  porccomm2:number= 20; 
  porccomm3:number=20;
  porccomm4:number = 20;
  visibleporccomm:boolean = true;
  visibleporccomm2:boolean = true;
  visibleporccomm3:boolean = true;
  visibleporccomm4:boolean = true;
  ComentComm:string="";
  ComentComm2:string="";
  ComentComm3:string="";
  ComentComm4:string="";
  StringCommClaim:string="";
  isEditable:boolean=true;
  ScCod1:string="";
  ScCod2:string="";
  ScCod3:string="";
  ScCod4:string="";
  DisabledField1:boolean=true;
  DisabledField2:boolean=true;
  DisabledField3:boolean=true;
  DisabledField4:boolean=true;

  
  @ViewChild('divCreateClient') private divCreateClient;
  @ViewChild('fcontainer') private fcontainer;
  @ViewChild('divShowProcedure') private divShowProcedure;
  @ViewChild('divCommisionClaim') private divCommisionClaim;
  @ViewChild('DivSearchSSN') private divSearchSSN;

  constructor(private router:Router,
    public http:WebserviceService, private modalService: BsModalService, public toastr: ToastrManager, private activatedRoute: ActivatedRoute) {

    activatedRoute.queryParams.subscribe
    (
      (params:Params)=>{
        var count = Object.keys(params).length;
        if (count > 0){
          this.ExecuteActiveRoute(params['method']);
        }
      }
    );
  }

  ngOnInit() {
    if (sessionStorage.getItem('IdentityName') == '' || sessionStorage.getItem('IdentityName')== null)
    {
      this.router.navigate(['/login']);
    }
    
    this.getAllpreparer();
    this.getTicketNumber();

    setInterval(() => {
      var currentdate = new Date();
      this.myDate =  currentdate.toLocaleString('en-US', { hour: 'numeric', minute:'numeric', second:'numeric', hour12: true }) // currentdate.getHours() + ":" + currentdate.getMinutes() + ":" + currentdate.getSeconds();
    }, 1000);

    
  }

  getAllpreparer(){
    this.http.getPreparer(this.http.root + "frm_employees")
    .then(data => {
      if (data["hasdata"] == true){
        this.personal = data["data"];
      }else {
        alert("no hay data");
      }
    });
  }

  changePreparer(val){
    alert(val);
  }
  
  IntroSSN(){
    this.ngSSN = this.FormatSSN(this.ngSSN);
    this.ClientSSN = this.ngSSN;
    this.ParamS["ssncl"] = this.ngSSN;
    this.ParamShowClient["ClientSSN"] = this.ngSSN;
    this.postClient();

    this.openModal(this.divCreateClient,false);
  }

  postClient(){
    this.http.postShowClient(this.http.root + "show_client", this.ParamShowClient)
    .then(data => {
      if(data["hasdata"] == true){
        this.InfoClient = data["data"];
        this.IdClient = data["data"][0]["IdClient"];
        this.ParamS["ssncl"] = data["data"][0]["TaxpayerId"];
        this.ClientName = data["data"][0]["LastName"] + ', ' + data["data"][0]["FirstName"];
        this.GetProcRec();
        this.FillListTicketsReceptions();
        
      }else{this.AlertWarning("Client Not Found"); this.ClearDataClient(); this.NewTicket();}
    }).catch(error => {
      console.log(error);
    });
  }

  GetProcRec(){
    var d = new Date();
    var n = d.getFullYear() - 1;
    this.ParamS["TaxYear"] = n;
    this.ParamS["IdClient"] = this.IdClient;
    this.http.ServerPost(this.http.root + "get_ProcRec", this.ParamS)
    .then(data => {
      if (data["hasdata"]==true){
        this.Proc_Reception = data["data"];
        //console.log(data["data"]);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getTicketNumber(){
    this.http.getTicketNumber(this.http.root + "showticketnumber")
    .then(data => {
      if (data["hasdata"] == true){
        this.TicketNumber = data["data"][0]["@MaxTicket"];
      }else {
        alert("no hay data");
      }
    });
  }

  OpenNewModal(wDivModal, Modify:boolean){
    if (Modify==true){
      if (this.ClientSSN != ""){
        this.ParamShowClient["ClientSSN"] = this.ClientSSN;
        this.postClient();
      }else{
        this.AlertWarning("Type Client SSN");
        return;
      }
    }

    $("#BackgroundModalPopup").css({ "opacity": "0.5" });
    $("#BackgroundModalPopup").fadeIn();
    $("#" + wDivModal).fadeIn();
  }

  CloseModal(wDivModal){
    $("#BackgroundModalPopup").fadeOut();
    $("#" + wDivModal).fadeOut();
  }

  openModal(divCreateClient: TemplateRef<any>, Modify:boolean) {
    
    if (Modify==true){
      if (this.ClientSSN != ""){
        this.ParamShowClient["ClientSSN"] = this.ClientSSN;
        this.postClient();
        this.modalRef = this.modalService.show(divCreateClient, {class:'modal-xl', backdrop: 'static'});
        return;
    }else {
        this.AlertWarning("Type SSN Client");
        return;
      }
    }
    this.ClearDataClient();
    this.modalRef = this.modalService.show(divCreateClient, {class:'modal-xl', backdrop: 'static'});
  } 

  openModalModifyClient(divCreateClient: TemplateRef<any>){

    if (this.ClientSSN != ""){
      this.ParamShowClient["ClientSSN"] = this.ClientSSN;
      this.postClient();
      //this.modalRef = this.modalService.show(divCreateClient, {class:'modal-lg', backdrop: 'static'});
    }else{
      this.AlertWarning("Select Valid Client");
    }
    
  }

  async SaveClient(f: NgForm) {
    var res;
    res = await this.ItCanSaveClient(f);
    if (!res){
      return;
    }
    var xSSN;
    xSSN = f.value.SocialSecutiyCustomer;
    xSSN = this.FormatSSN(xSSN);
    if (f.value.DOBCustomer == '' || f.value.DOBCustomer == null) {
      f.value.DOBCustomer = '1900-01-01';
    }
    else {f.value.DOBCustomer = moment(f.value.DOBCustomer).format("YYYY-MM-DD")};
    f.value.IdClient = this.IdClient;

    if (f.value.DOBCustomer == "Invalid date"){
      f.value.DOBCustomer = '1900-01-01';
    }
    f.value.SocialSecutiyCustomer = xSSN;
    this.http.clientput(this.http.root + "save_client", f.value)
    .then(data => {
      if (data["data"][0]["@MaxCod"] > 0){
        this.IdClient = data["data"][0]["@MaxCod"];
        this.ngSSN=xSSN;
        this.ClientName = f.value.LastNameCustomer + ', ' + f.value.FirstNameCustomer;
        this.showSuccess("Client Saved !!");
        this.CloseModal("divCreateClient");
      }
    }).catch(error => {
      console.log("error sending Client: " + error);
    });
  }

  ClearDataClient(){
    this.InfoClient[0].LastName = "";
    this.InfoClient[0].FirstName = "";
    this.InfoClient[0].MiddleInitial = "";
    this.InfoClient[0].EMail = "";
    this.InfoClient[0].cStatus = 2;
    this.InfoClient[0].City = "";
    this.InfoClient[0].cState = 0;
    this.InfoClient[0].ZipCode = "";
    this.InfoClient[0].BirthDate = moment('1900-01-01').format("YYYY-MM-DD");
    this.InfoClient[0].cSource = 0;
    this.InfoClient[0].Comment = "";
    this.InfoClient[0].Sex = 1;
    this.InfoClient[0].LanguagePreferred = 1;
    this.InfoClient[0].StreetAddress = "";
    this.InfoClient[0].Phone = "";
    this.InfoClient[0].cMovil = "";
    this.InfoClient[0].OtherPhone = "";
    this.InfoClient[0].TaxpayerId = "";
    this.ArrayListTIcket = [];
    this.Proc_Reception = [
      {
        proc1:0,proc2:0,proc3:0,proc4:0,proc5:0,proc6:0,proc7:0,proc8:0,proc9:0,proc10:0,
        proc11:0,proc12:0,proc13:0,proc14:0,proc15:0,proc16:0,

        proc17:0,proc18:0,proc19:0,proc20:0,proc21:0,proc22:0,proc23:0,proc24:0,proc25:0,proc26:0,

        proc27:0,proc28:0,proc29:0,proc30:0,proc31:0,proc32:0,proc33:0,proc34:0,proc35:0,proc36:0,
        proc37:0,proc38:0,proc39:0,proc40:0, proc41:0, proc42:0, proc43:0, proc44:0, proc45:0,
      proc46:0, proc47:0, proc48:0, proc49:0, proc50:0, proc51:0, proc52:0, proc53:0
      }
    ];
  }

  NewTicket(){
    this.ClientName = "";
    this.getTicketNumber();
    this.idPrep = 47;
    $("#ReasonVisit").val('');
    $("#TypeService").val('');
    $("#wSSN").val('');
    $("#TypeService").val(0);
    $("#TicketTypeClient").val(0);
    $('.xcheckbox').each(function(){ this.checked = false; });
    this.IdClient = 0;
    this.ClientSSN = "";
    this.ArrayListTIcket = [];
  }

  AlertWarning(msg) {
    this.toastr.warningToastr(msg, 'Alert', {toastLife: 5000, showCloseButton:true});
  }

  AlertError(msg) {
    this.toastr.errorToastr(msg, 'Error', {toastLife: 5000, showCloseButton:true});
  } 

  showSuccess(msg) {
    this.toastr.successToastr(msg, 'Success!', {toastLife: 2000, showCloseButton:true});
  }

  CreateTicket(f:NgForm){
    if (this.CanBeSave() == true){
      f.value.wSSN = $("#wSSN").val();
      this.http.postCreateTicket(this.http.root + "CreateNewTicket", f.value)
      .then(data => {
        this.IdTicket = data["data"][0]["@MaxTicketId"];
        this.PrintTicket(data["data"][0]["@MaxTicketId"]);
        this.showSuccess("Ticket Sent !!");
        this.NewTicket();
      }).catch(error => {
        console.log("error sending ticket")
      });
    }
    
  }

  SaveTicket(f:NgForm){
    if (this.CanBeSave() == true){
      f.value.wSSN = $("#wSSN").val();
      this.http.postCreateTicket(this.http.root + "CreateNewTicket", f.value)
      .then(data => {
        this.IdTicket = data["data"][0]["@MaxTicketId"];
        this.showSuccess("Ticket Saved !!");

        this.NewTicket();
      }).catch(error => {
        console.log("create ticket: " + error);
      });
    }
  }

  async SaveProcRec(f:NgForm){
    let vps;
    if (f.value.SecCode.length == 0){
      this.AlertWarning("Username Password Missing");
      return false;
    }
    vps = await this.VerifyPrepPass(f.value.SecCode);
    if (vps == 0){
      this.AlertWarning("Password not valid");
      return;
    }
    var d = new Date();
    var n = d.getFullYear() - 1;
    f.value.TaxYear = n;
    this.http.clientput(this.http.root + "save_ProcRec", f.value);
    this.showSuccess("Info Saved !!");
  }

  PrintTicket(wTicket){
    this.ParamS["wTicket"] = wTicket
    this.ParamS["wPC"] = $("#SelectPrinter").val();
    
    this.http.ServerPost(this.http.root + "reportticket", this.ParamS)
    this.showSuccess("Printing Ticket...");
  }

  FillListTicketsReceptions(){
    this.http.ServerPost(this.http.root + "ShowListClientTicket", this.ParamS)
    .then(data => {
      this.ArrayListTIcket = data["data"];
    }).catch(error => {
      console.log("error showing Clients tickets " + error);
    });
  }

  //PrintTIcket(wIdTicket){
    //alert(wIdTicket);
  //}

  ChangeBinTicket(wIdTicket){
    this.ParamS["IdTicket"] = wIdTicket;
    this.ParamS["StatusTicket"] = 1;
    this.http.ServerPost(this.http.root + "UpdateBinWorkFlow", this.ParamS)
    .then(data => {
      this.showSuccess("Ticket Changed !!");
    }).catch(error => {
      console.log("error showing Clients tickets");
    });
  }

  async ItCanSaveClient(f: NgForm){
    let ssc;
    let cc;
    let vps;
    ssc = f.controls.SocialSecutiyCustomer;

    if (f.controls.SocialSecutiyCustomer.valid == false){
      this.AlertWarning("SSN Invalid");
      return false;
    }
    if (f.controls.LastNameCustomer.valid == false){
      this.AlertWarning("Last Name Invalid");
      return false;
    }
    if (f.controls.FirstNameCustomer.valid == false){
      this.AlertWarning("First Name Invalid");
      return false;
    }
    if (f.value.SecCode.length == 0){
      this.AlertWarning("Username Password Missing");
      return false;
    }
    //if (this.UserPass != f.value.SecCode){
      //console.log(localStorage.getItem('SecurityCode') + " fcod: " + f.value.SecCode);
      //this.AlertWarning("Username Password Missing");
      //return false;
    //}

    vps = await this.VerifyPrepPass(f.value.SecCode);
    if ( vps == 0){
      this.AlertWarning("Password not valid");
      return;
    }    

    cc = await this.VerifyRegistedClient(ssc);

    Observable.forkJoin([cc]);

    if(this.v_Client>0){
      return false;
    }else{return true;}
    
  }

  async VerifyRegistedClient(wIdClient){
    if (this.IdClient > 0){
      this.v_Client=0;
      return;
    }
    this.ParamS["SSN"] = wIdClient.value;
    await this.http.FillListTicketsReception(this.http.root + "VerifyRegistedClient", this.ParamS)
    .then(data => {
      if (data["data"][0]["@IdClient"]>0){
        this.AlertWarning("this SSN is used by another client");
        this.v_Client= data["data"][0]["@IdClient"];
      }else{
        this.v_Client=0;
      }
    });
  }  

  ItCanCreateTicket(f: NgForm){
    
  }

  VerifySecurity(Md){
    this.ParamS["wUserName"] = sessionStorage.getItem('IdentityName');
    this.ParamS["wModule"] = Md;
    this.http.ServerPost(this.http.root + "VerifySecurity", this.ParamS)
    .then(data => {
      if (data["data"][0]["Allow"] == 0){
        this.AlertWarning("Access Denied");
        return false;
      }
      switch(Md){
        case 5:
          this.NewTicket();
          this.ClearDataClient();
          this.openModal(this.divCreateClient, false);
          return;

      }
      if (Md == 1) {
          //this.GoToRegister();
      }
      if (Md == 4) {
          this.CreateTicket(this.fcontainer);
          return;
      }
      if (Md == 29) {
          this.openModal(this.divCreateClient, true);
          return;
      }
      
    }).catch(error => {
      console.log("error sending: " + error);
    });
  }

  openTemplate(divModal: TemplateRef<any>) {
    
    this.modalRef = this.modalService.show(divModal, {class: 'modal-xl', backdrop: 'static'});
  }

  FindAllClient(){
    let cName;
    cName = $("#iptSearchClient").val();
    this.ParamShowClient["Name"] = cName;
    this.ParamShowClient["IdClient"] = 0;

    this.http.ServerPost(this.http.root + "show_client", this.ParamShowClient)
    .then(data => {
      if (data["hasdata"] == true){
        this.IdClient = data["data"][0]["IdClient"];
        this.ArrayFindClient = data["data"];
      }else {
        //alert("no data");
        this.AlertWarning("Client not found");
      }
    });
  }

  SubmitFind(ssnClient){
    this.ParamShowClient["ClientSSN"] = ssnClient;
    this.ngSSN = ssnClient;
    this.postClient();
  }

  async VerifyPrepPass(wPass){
    let rIdPrep;
    let wIdPrep;
    rIdPrep = await this.http.VerifyPrepPass(wPass);

    if (rIdPrep == 0){
      return 0;
    }else{
      this.UserPass = wPass;
      this.verifyPass(rIdPrep);
      }
  }

  OpenProceduresDiv(){
    if (this.IdClient == 0){
      this.AlertWarning("Select Client");
      return;
    }
    this.openTemplate(this.divShowProcedure);
  }

  ShowDivCommClaim(){
    if (this.IdClient == 0){
      this.AlertWarning("Select Client");
      return;
    }
    let IdEmp = parseInt(localStorage.getItem('employeeID'));
    let d = new Date();
    this.ClearCommClaim();
    //this.IdClient = $("#IdClient").val();
    
    this.ParamShowClient["IdClient"] = this.IdClient;
    this.ParamShowClient["taxyear"] = d.getFullYear() - 1;
    this.http.ServerPost(this.http.root + "GetInfoCommClaim", this.ParamShowClient)
    .then(data => {
      if(data["hasdata"] == true){
        this.idprep = data["data"][0]["idprep"];
        this.idprep2=data["data"][0]["idprep2"];
        this.idprep3=data["data"][0]["idprep3"];
        this.idprep4=data["data"][0]["idprep4"];
        if(data["data"][0]["datecomm"]==null){
          this.datecomm = moment().format('L');
        }else{this.datecomm=moment(data["data"][0]["datecomm"]).format("YYYY-MM-DD");}
        if(data["data"][0]["datecomm2"]==null){
          this.datecomm2=this.datecomm = moment().format('L');
        }else{this.datecomm2=moment(data["data"][0]["datecomm2"]).format("YYYY-MM-DD");}
        if(data["data"][0]["datecomm3"]==null){
          this.datecomm3=this.datecomm = moment().format('L');
        }else{this.datecomm3=moment(data["data"][0]["datecomm3"]).format("YYYY-MM-DD");}
        if(data["data"][0]["datecomm4"]==null){
          this.datecomm4=this.datecomm = moment().format('L');
        }else{this.datecomm4=moment(data["data"][0]["datecomm4"]).format("YYYY-MM-DD");}
        this.porccomm=data["data"][0]["porccomm"];
        this.porccomm2=data["data"][0]["porccomm2"];
        this.porccomm3=data["data"][0]["porccomm3"];
        this.porccomm4=data["data"][0]["porccomm4"];
        this.ComentComm=data["data"][0]["commentcomm"];
        this.ComentComm2=data["data"][0]["commentcomm2"];
        this.ComentComm3=data["data"][0]["commentcomm3"];
        this.ComentComm4=data["data"][0]["commentcomm4"];

        if (IdEmp == 6 || IdEmp == 1){
          this.visibleporccomm=true;
          this.visibleporccomm2=true;
          this.visibleporccomm3=true;
          this.visibleporccomm4=true;
        }else{
        
          if (IdEmp == this.idprep){
            this.visibleporccomm=true;
          }else{this.visibleporccomm=false;}
          if (IdEmp == this.idprep2){
            this.visibleporccomm2=true;
          }else{this.visibleporccomm2=false;}
          if (IdEmp == this.idprep3){
            this.visibleporccomm3=true;
          }else{this.visibleporccomm4=false;}
          if (IdEmp == this.idprep4){
            this.visibleporccomm4=true;
          }else{this.visibleporccomm4=false;}
        }
      }
    }).catch(error => {
      console.log(error);
    });

    this.openTemplate(this.divCommisionClaim);
  }

  ClearCommClaim(){
    this.idprep = 47;
    this.idprep2=47;
    this.idprep3=47;
    this.idprep4=47;
    this.datecomm= moment().format('L');
    this.datecomm2= moment().format('L');
    this.datecomm3= moment().format('L');
    this.datecomm4= moment().format('L');
    this.porccomm=20;
    this.porccomm2=20;
    this.porccomm3=20;
    this.porccomm4=20;
    this.ComentComm='';
    this.ComentComm2='';
    this.ComentComm3='';
    this.ComentComm4='';
    this.visibleporccomm = false;
    this.visibleporccomm2= false;
    this.visibleporccomm3= false;
    this.visibleporccomm4 = false;
  }

  SubmitCommissionClaim(f: NgForm){
    let v_c = this.IdClient;
    this.ParamS["CommentComm"]=f.controls.CommentComm.value;
    this.ParamS["CommentComm2"]=f.controls.CommentComm2.value;
    this.ParamS["CommentComm3"]=f.controls.CommentComm3.value;
    this.ParamS["CommentComm4"]=f.controls.CommentComm4.value;
    this.ParamS["DateComm"]=f.controls.DateComm.value;
    this.ParamS["DateComm2"]=f.controls.DateComm2.value;
    this.ParamS["DateComm3"]=f.controls.DateComm3.value;
    this.ParamS["DateComm4"]=f.controls.DateComm4.value;
    this.ParamS["IdClient"]=this.IdClient;
    this.ParamS["PorcComm"]=f.controls.PorcComm.value;
    this.ParamS["PorcComm2"]=f.controls.PorcComm2.value;
    this.ParamS["PorcComm3"]=f.controls.PorcComm3.value;
    this.ParamS["PorcComm4"]=f.controls.PorcComm4.value;
    this.ParamS["PreCommClaim"]=f.controls.PreCommClaim.value;
    this.ParamS["PreCommClaim2"]=f.controls.PreCommClaim2.value;
    this.ParamS["PreCommClaim3"]=f.controls.PreCommClaim3.value;
    this.ParamS["PreCommClaim4"]=f.controls.PreCommClaim4.value;
    this.http.ServerPost(this.http.root + "SaveCommissionClaim", this.ParamS)
    .then(data => {
      if(data["success"] == true){
        this.showSuccess("Info Saved!!");
        this.modalRef.hide();
      }
    }).catch(error => {
      console.log(error);
    });
  }

  verifyPass(wIdPrep){
    if(this.ScCod1 == this.UserPass){
      if (this.idprep != 47){
        if (wIdPrep == this.idprep){
          this.DisabledField1 =false;
          this.idprep = wIdPrep;
          this.visibleporccomm = true;  
          return;
        }
        if (wIdPrep != 1 && wIdPrep != 8 && wIdPrep != 1091) {
          this.AlertWarning("Access Denied")
          return;
        }
        if (wIdPrep == 1 || wIdPrep == 8 || wIdPrep == 1091){
          this.DisabledField1 =false;
          this.idprep = this.idprep;
          this.visibleporccomm = true;  
          return;
        }
      }
      this.DisabledField1 =false;
      this.idprep = wIdPrep;
      this.visibleporccomm = true;
    }
    if(this.ScCod2 == this.UserPass){
      if (this.idprep2 != 47){
        if (wIdPrep == this.idprep2){
          this.DisabledField2 =false;
          this.idprep2 = wIdPrep;
          this.visibleporccomm2 = true;  
          return;
        }
        if (wIdPrep != 1 && wIdPrep != 8 && wIdPrep != 1091){
          this.AlertWarning("Access Denied")
          return;
        }
        if (wIdPrep == 1 || wIdPrep == 8 || wIdPrep == 1091){
          this.DisabledField2 =false;
          this.idprep2 = this.idprep2;
          this.visibleporccomm2 = true;  
          return;
        }
      }
      this.DisabledField2 =false;
      this.idprep2 = wIdPrep
      this.visibleporccomm2 = true;
    }
    if(this.ScCod3 == this.UserPass){
      if (this.idprep3 != 47){
        if (wIdPrep == this.idprep3){
          this.DisabledField3 =false;
          this.idprep3 = wIdPrep;
          this.visibleporccomm3 = true;  
          return;
        }
        if (wIdPrep != 1 && wIdPrep != 8 && wIdPrep != 1091){
          this.AlertWarning("Access Denied")
          return;
        }
        if (wIdPrep == 1 || wIdPrep == 8 || wIdPrep == 1091){
          this.DisabledField3 =false;
          this.idprep3 = this.idprep3;
          this.visibleporccomm3 = true;  
          return;
        }
      }
      this.DisabledField3 =false;
      this.idprep3 = wIdPrep
      this.visibleporccomm3 = true;
    }
    if(this.ScCod4 == this.UserPass){
      if (this.idprep4 != 47){
        if (wIdPrep == this.idprep4){
          this.DisabledField4 =false;
          this.idprep4 = wIdPrep;
          this.visibleporccomm4 = true;  
          return;
        }
        if (wIdPrep != 1 && wIdPrep != 8 && wIdPrep != 1091){
          this.AlertWarning("Access Denied")
          return;
        }
        if (wIdPrep == 1 || wIdPrep == 8 || wIdPrep == 1091){
          this.DisabledField4 =false;
          this.idprep4 = this.idprep4;
          this.visibleporccomm4 = true;  
          return;
        }
      }
      this.DisabledField4 =false;
      this.idprep4 = wIdPrep
      this.visibleporccomm4 = true;
    }
    
  }

  verifyPassByButton(){
    this.AlertWarning("Password not valid"); return;
   }

  ExecuteActiveRoute(wMethod){
    switch (wMethod){
      case ('searchclient'):
        this.openTemplate(this.divSearchSSN);
        break;
      case ('newclient'):
        this.VerifySecurity(5);
        break;
      case ('modclient'):
        this.VerifySecurity(29);
        break;
      case ('newticket'):
        this.ClearDataClient(); 
        this.NewTicket();
        break;
      case ('foto'):
        this.VerifySecurity(5);
        break;
      case ('procedures'):
        this.OpenProceduresDiv();
        break;
      case ('commissions'):
        this.ShowDivCommClaim();
        break;
    }
  }

  FormatSSN(wSSN){
    let len = wSSN.toString().length;
    let string = wSSN.toString();
    let newssn:any;
    newssn = string.substring(0,3) + "-" + string.substring(3,5) + "-" + string.substring(5,9);
    return newssn;
  }

  CanBeSave(){
    if ($("#wSSN").val() == ''){
      this.AlertWarning("SSN Not Valid");
      return false;
    }
    if ($("#ReasonVisit").val() == ''){
      this.AlertWarning("Reason for Visit Not Valid");
      return false;
    }
    if ($("#NameClientTicket").val() == ''){
      this.AlertWarning("Client Not Valid, Verify SSN");
      return false;
    }
    if ($("#SelectSourceCustomer").val() == 0){
      this.AlertWarning("Source Option Not Valid");
      return false;
    }
    return true;
  }

}
