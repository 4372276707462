import { WebserviceService } from '../../webservice.service';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';

interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

//let http = 'http://localhost:4200/#/'; //new WebserviceService();
let xhttp = new WebserviceService(null,null);
let yhttp = xhttp.getUrl();
let http = 'http://' + yhttp + '/#/';

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fa fa-home fa-fw'
  },
  {
    name: 'New Client',
    url: http + 'reception?method=newclient',
    icon: 'fa fa-user fa-fw'
  },
  {
    name: 'Modify Client',
    url: http + 'reception?method=modclient',
    icon: 'fa fa-edit fa-fw'
  },
  {
    name: 'New Ticket',
    url: http + 'reception?method=newticket',
    icon: 'fa fa-ticket fa-fw'
  },
  {
    name: 'Search Client',
    url: http + 'reception?method=searchclient',
    icon: 'fa fa-search fa-fw'
  },
  {
    name: 'Foto',
    url: http + 'reception?method=foto',
    icon: 'fa fa-camera fa-fw'
  },
  {
    name: 'Procedures',
    url: http + 'reception?method=procedures',
    icon: 'fa fa-check-square-o fa-fw'
  },
  {
    name: 'Commissions',
    url: http + 'reception?method=commissions',
    icon: 'fa fa-money'
  }
];
