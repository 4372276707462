import { Component, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';

import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ToastrManager } from 'ng6-toastr-notifications';
import { WebserviceService } from '../../webservice.service';

import { FormsModule, NgForm } from '@angular/forms';

import * as moment from 'moment';

//import * as $ from 'jquery';

declare var $;

@Component({
  selector: 'app-faxlog',
  templateUrl: './faxlog.component.html',
  styleUrls: ['./faxlog.component.scss']
})
export class FaxlogComponent implements OnInit {

  ParamS:any = {};
  wIdFax:number=0;
  jsonData:any={};
  
  DateFax:string;
  FaxNumber:string;

  constructor(private router:Router, public http:WebserviceService) { }

  ngOnInit() {

  	if (sessionStorage.getItem('IdentityName') == '' || sessionStorage.getItem('IdentityName')== null)
    {
      //this.router.navigate(['/login']);
    }
  	this.GetYears();
  	this.ShowData();
    this.confiPage();

  }

  GetYears(){
  	var Dates = new Date();
  	var i;
    $("#SelectYear").html("");
    for (i = 2016; i <= Dates.getFullYear() ; i++) {
        $("#SelectYear").append($("<option></option>").attr("value", i).text(i));
    }
    $("#SelectYear").val(Dates.getFullYear() - 1);
  }

  ShowData(){

    var zYear;
    var grid;
    var jData;

    this.ParamS["wYear"] = $("#SelectYear").val();
    this.http.ServerPost(this.http.root + "GetDataFaxLog", this.ParamS)
      .then(data => {
        this.jsonData = JSON.stringify(data["data"]);
        this.jsonData = JSON.parse(this.jsonData);

        $("#jQGridFaxLog").jqGrid('GridUnload');
          var grid = $("#jQGridFaxLog").jqGrid({
            datatype: "local",
            colNames: ['', 'Date', 'Fax Number', 'Time', 'Pages', 'SENDER', 'RECEIVER', 'ACTION'],
            colModel: [
                { name: 'IdFaxLog', hidden: true},
                { name: 'datefax', index: 'datefax', editable: true },
                { name: 'FaxNumber', editable: true },
                { name: 'FaxTime', editable: true },
                { name: 'FaxPageAccount', editable: true },
                { name: 'FaxSender', editable: true },
                { name: 'FaxReceiver', editable: true },
                { name: 'Action', editable: true }
            ],
            data: data["data"],
            caption: "Fax List",
            pager: "#jqGridPager",
            viewrecords: true,
            height: "auto",
            autowidth: true,
            processing: true,
            paging: true,
            rowNum: 10,
            rowList: [10, 20, 30],
            cellsubmit: 'clientArray',
            onCellSelect: function (row, col, content, event, cellname) {
                var rowData = $(this).getRowData(row);
                
                $("#IdFaxLog").val(rowData["IdFaxLog"]);
                $("#datefax").val(rowData["datefax"]);
                $("#FaxNumber").val(rowData["FaxNumber"]);
                $("#FaxTime").val(rowData["FaxTime"]);
                $("#FaxPageAccount").val(rowData["FaxPageAccount"]);
                $("#FaxSender").val(rowData["FaxSender"]);
                $("#FaxReceiver").val(rowData["FaxReceiver"]);
                $("#Action").val(rowData["Action"]);

            },
            //editurl: "http://localhost:3000/GetDataFaxLog/"
        });
        grid.jqGrid('navGrid', '#jqGridPager',
          { edit: true, add: true, del: true, refresh: true, view: true }, {
              editCaption: "Edit Record",
              edittext: "Edit",
              closeOnEscape: true,
              closeAfterEdit: true,
              savekey: [true, 13],
              //errorTextFormat: commonError,
              width: "300",
              reloadAfterSubmit: true,
              bottominfo: "Fields marked with (*) are required",
              top: "60",
              left: "5",
              right: "5",
              //url: "/Pupilo/WebServiceGeneral.asmx/GetDataFaxLog",
              //editData: { wYear: $("#SelectYear").val() },
              onclickSubmit: function (response, postdata) {
                
                $("#EjectEditData").click();
              }
          },
          {
            addCaption: "Add Record",
            addtext: "Add",
            closeOnEscape: true,
            closeAfterAdd: true,
            savekey: [true, 13],
            width: "300",
            reloadAfterSubmit: true,
            bottominfo: "Fields marked with (*) are required",
            top: "60",
            left: "5",
            right: "5",
            errorTextFormat: "Error to submit, verify date format",
            beforeSubmit: function (postdata, formid) {
                $("#IdFaxLog").val(postdata["IdFaxLog"]);
                $("#datefax").val(postdata["datefax"]);
                $("#FaxNumber").val(postdata["FaxNumber"]);
                $("#FaxTime").val(postdata["FaxTime"]);
                $("#FaxPageAccount").val(postdata["FaxPageAccount"]);
                $("#FaxSender").val(postdata["FaxSender"]);
                $("#FaxReceiver").val(postdata["FaxReceiver"]);
                $("#Action").val(postdata["Action"]);
            },
            onclickSubmit: function (response, postdata) {
              $("#EjectAddData").click();
            }
          },
          {
            beforeSubmit: function (response, postdata) {
              $("#IdFaxLog").val(postdata["IdFaxLog"]);
            },
              onclickSubmit: function (response, postdata) {
                $("#EjectDeleteData").click();
              }
          }
          );
      });

      
  }

  EditPost(){
    var dateFax = $("#datefax").val();
    dateFax = moment(dateFax).format("YYYY-MM-DD");
    this.ParamS["IdFax"] = $("#IdFaxLog").val();
    this.ParamS["FaxDate"] = dateFax;
    this.ParamS["FaxNumber"] = $("#FaxNumber").val();
    this.ParamS["FaxTime"] = $("#FaxTime").val();
    this.ParamS["FaxPages"] = $("#FaxPageAccount").val();
    this.ParamS["FaxSender"] = $("#FaxSender").val();
    this.ParamS["FaxReceiver"] = $("#FaxReceiver").val();
    this.ParamS["FaxAction"] = $("#Action").val();
    this.ParamS["TaxYear"] = $("#SelectYear").val();

    this.http.ServerPost(this.http.root + "SaveFaxLogs", this.ParamS)
  }

  AddPost(){
    var dateFax = $("#datefax").val();
    dateFax = moment(dateFax).format("YYYY-MM-DD");
    this.ParamS["IdFax"] = 0;
    this.ParamS["FaxDate"] = dateFax;
    this.ParamS["FaxNumber"] = $("#FaxNumber").val();
    this.ParamS["FaxTime"] = $("#FaxTime").val();
    this.ParamS["FaxPages"] = $("#FaxPageAccount").val();
    this.ParamS["FaxSender"] = $("#FaxSender").val();
    this.ParamS["FaxReceiver"] = $("#FaxReceiver").val();
    this.ParamS["FaxAction"] = $("#Action").val();
    this.ParamS["TaxYear"] = $("#SelectYear").val();

    this.http.ServerPost(this.http.root + "SaveFaxLogs", this.ParamS)
  }

  DeletePost(){
    this.ParamS["IdFax"] = $("#IdFaxLog").val();
    this.http.ServerPost(this.http.root + "DeleteFaxLog", this.ParamS)
  }

  confiPage(){
    $(window).unbind('resize.myEvents').bind('resize.myEvents', function () {
        var outerwidth = $('.jqGrid').width();
        $('#jQGridFaxLog').setGridWidth(outerwidth);
    });

    var getColumnIndexByName = function (gr, columnName) {
        var cm = gr.jqGrid('getGridParam', 'colModel');
        for (var i = 0, l = cm.length; i < l; i++) {
            if (cm[i].name === columnName) {
                return i; // return the index
            }
        }
        return -1;
    };
  }

}
