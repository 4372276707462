import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { WebserviceService } from '../../webservice.service';

import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit { 

  personal: any = [];
  ParamS:any={};

  CompleteUserName:string='';

  constructor(private router:Router, public http:WebserviceService, public toastr: ToastrManager) {}

  ngOnInit() {
  	this.http.logout();	
  }

  async loginUser(e){
    let v_ip;
    e.preventDefault();
  	var username = e.target.elements[0].value;
  	var password = e.target.elements[1].value;
    //var wtLog = tLog.getHours() + ':' + tLog.getMinutes();

    v_ip = await this.http.GetUseIp().then(res => {return res["ip"]});
    this.ParamS["wUserName"] = username;
    this.ParamS["wIp"] = v_ip;

    this.http.ServerPost(this.http.root + "RegistraUsuarios", this.ParamS)
    .then(data => {
      if (data["hasdata"] == true){
        if (username == data["data"][0]["NombreUsuario"] && password == data["data"][0]["clave"]){
          if (data["data"][0]["PassConfirm"] == 0){
            localStorage.setItem('password', password);
            sessionStorage.setItem('IdentityName', username);
            localStorage.setItem('employeeID', data["data"][0]["employeeID"]);
            sessionStorage.setItem("CompleteEmployeeName", data["data"][0]["Nombre"]);
            this.router.navigate(['Account/changepassword']);

            this.http.CompleteUserName.next(data["data"][0]["Nombre"]);
          }
          else {
            localStorage.setItem('password', password);
            sessionStorage.setItem('IdentityName', username);
            localStorage.setItem('employeeID', data["data"][0]["employeeID"]);
            sessionStorage.setItem("CompleteEmployeeName", data["data"][0]["Nombre"])

            this.http.CompleteUserName.next(data["data"][0]["Nombre"]);

            if (data["data"][0]["HasUnread"]==1){
              this.http.NewSmsComing.next(1);
            }

            this.http.sendToken(data["data"][0]["Nombre"]);

            this.router.navigate(['./dashboard']);
          }
        }
        else{
          this.AlertWarning("UserName or Password Incorrect")
          //alert("Not valid user");
        }
      }else {
console.log(data["data"]);
        this.AlertError("Connection not valid, please contact administrator system");
      }
    }).catch(error => {
      console.log("error: " + error);
    });
  }

  AlertWarning(msg) {
    this.toastr.warningToastr(msg, 'Alert', {toastTimeout: 5000, showCloseButton:true});
    }

  AlertError(msg) {
    this.toastr.errorToastr(msg, 'Error', {toastTimeout: 5000, showCloseButton:true});
    } 

  showSuccess(msg){
    this.toastr.successToastr(msg, 'Success!', {toastTimeout: 2000, showCloseButton:true});
    }

}
