import { Component, OnInit, TemplateRef, ViewContainerRef, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ToastrManager } from 'ng6-toastr-notifications';
import { WebserviceService } from '../../webservice.service';

import { FormsModule, NgForm } from '@angular/forms';

import * as moment from 'moment';



//import * as $ from 'jquery/dist/jquery.min.js';

//import * as $ from 'free-jqgrid/dist/jquery.jqgrid.min.js';

declare var $;

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit {

  modalRef: BsModalRef;

  personal: any = [];
  Employees:any =[];

  IdClient:number = 0;
  
  ParamShowClient:any = {};
  ArrayInfoClient:any = [];
  ListBinWorkFlow:any = [];
  IdBin:number = 2;
  InfoPersonal:any = [];
  ParamS:any = {};
  wIdTicket:number=0;
  wSelectBin:string="";
  PreparerList:string="";
  EmployeeList:string="";
  ListData:any={};
  idprep:number=47;
  idprep2:number=47;
  idprep3:number=47;
  idprep4:number=47;
  datecomm:string= moment().format('L') + ' ' + moment().format('LT');
  datecomm2:string= moment().format('L') + ' ' + moment().format('LT');
  datecomm3:string = moment().format('L') + ' ' + moment().format('LT');
  datecomm4:string = moment().format('L') + ' ' + moment().format('LT');
  //default mount 20%
  porccomm:number =20;
  porccomm2:number= 20; 
  porccomm3:number=20;
  porccomm4:number = 20;
  visibleporccomm:boolean = true;
  visibleporccomm2:boolean = true;
  visibleporccomm3:boolean = true;
  visibleporccomm4:boolean = true;
  ComentComm:string="";
  ComentComm2:string="";
  ComentComm3:string="";
  ComentComm4:string="";
  StringCommClaim:string="";
  isEditable:boolean=true;
  UserPass:string=localStorage.getItem('password');
  ScCod1:string="";
  ScCod2:string="";
  ScCod3:string="";
  ScCod4:string="";
  DisabledField1:boolean=true;
  DisabledField2:boolean=true;
  DisabledField3:boolean=true;
  DisabledField4:boolean=true;
  Proc_Reception:any = [
    {
      proc1:0,proc2:0,proc3:0,proc4:0,proc5:0,proc6:0,proc7:0,proc8:0,proc9:0,proc10:0,
      proc11:0,proc12:0,proc13:0,proc14:0,proc15:0,proc16:0,

      proc17:0,proc18:0,proc19:0,proc20:0,proc21:0,proc22:0,proc23:0,proc24:0,proc25:0,proc26:0,

      proc27:0,proc28:0,proc29:0,proc30:0,proc31:0,proc32:0,proc33:0,proc34:0,proc35:0,proc36:0,
      proc37:0,proc38:0,proc39:0,proc40:0, proc41:0, proc42:0, proc43:0, proc44:0, proc45:0,
      proc46:0, proc47:0, proc48:0, proc49:0, proc50:0, proc51:0, proc52:0, proc53:0
    }
  ];
  TittleBottonRefres:string="Refresh Data";
  visibleFieldOver24h:boolean=false;
  visibleFieldRemoveFilter:boolean=false;
  interval:any;
  timeInit:any;
  countRefresh:number=0;
  ArrayListTIcket:any = [];


  @ViewChild('divCommisionClaim') private divCommisionClaim;
  @ViewChild('divCustomerInfo') private divCustomerInfo;
  @ViewChild('divFindClient') private divFindClient;
  @ViewChild('divProcedure') private divProcedure;
  @ViewChild('SelectFilterPreparer') private SelectFilterPreparer;
  @HostListener('window:beforeunload')
  doSomething() {
    var vt = moment();
    var tt = vt.diff(this.timeInit,'minutes');
    if (tt < 1){
      //this.router.navigate(['/login']);
    }
  }

  constructor(private router:Router,
      public http:WebserviceService, private modalService: BsModalService, public toastr: ToastrManager) { }

  ngOnInit() {
    if (sessionStorage.getItem('IdentityName') == '' || sessionStorage.getItem('IdentityName')== null)
    {
      this.router.navigate(['/login']);
    }
    this.timeInit = moment();
    setTimeout(()=>{this.getAllEmploee();},1000);
    setTimeout(()=>{this.getAllpreparer();},1000);
    this.getListBinWorkFlow();
    this.GetYears();
    setTimeout(()=>{this.GetListData(1,0);},1000);
    
    this.InitInterval();
  }

  getAllpreparer(){
    var SelectData;
    var a_personal=[];
    this.http.ServerGet(this.http.root + "frm_employees")
    .then(data => {
      if (data["hasdata"] == true){
        this.personal = data["data"];

        SelectData = JSON.stringify(data["data"]);
        SelectData = JSON.parse(SelectData);

        var objJson = SelectData;
        for(var i = 0; i < objJson.length; i++ ){
          if (objJson[i].IsTaxPreparer != 4){
            this.PreparerList += objJson[i].EmployeeID + ":" + objJson[i].EmployeeName + ";";
          }
        }

      }else {
        alert("not prep. data");
      }
    });
  }

  getAllEmploee(){
    var SelectData;

    this.ParamShowClient["wEmp"] = 0;
    this.ParamShowClient["DirectionID"] = 0;
    this.ParamShowClient["ShowInactive"] = 0;

    this.http.ServerPost(this.http.root + "FillListEmployee", this.ParamShowClient)
    .then(data => {
      if (data["hasdata"] == true){
        this.Employees = data["data"];

        SelectData = JSON.stringify(data["data"]);
        SelectData = JSON.parse(SelectData);

        var objJson = SelectData;
        for(var i = 0; i < objJson.length; i++ ){
          if (objJson[i].IsTaxPreparer != 4){
            this.EmployeeList += objJson[i].EmployeeID + ":" + objJson[i].EmployeeName + ";";
          }
        }

      }else {
        alert("no hay data");
      }
    });
  }

  getListBinWorkFlow(){
    var SelectData;
    this.http.ServerGet(this.http.root + "ListBinWorkFlow")
    .then(data => {
      if (data["hasdata"] == true){
        this.ListBinWorkFlow = data["data"];
        SelectData = JSON.stringify(data["data"]);
        SelectData = JSON.parse(SelectData);

        var objJson = SelectData;
        for(var i = 0; i < objJson.length; i++ ){
          this.wSelectBin += objJson[i].IdBin + ":" + objJson[i].BinName + ";";
        }

      }else {
        alert("not bin data");
      }
    });
  }

  GetYears(){
    var Dates = new Date();
    var i;
    $("#YearFindWorkFlow").html("");
    for (i = 2015; i <= Dates.getFullYear() - 1 ; i++) {
        $("#YearFindWorkFlow").append($("<option></option>").attr("value", i).text(i));
    }
    $("#YearFindWorkFlow").val(Dates.getFullYear() - 1);
  }

  async GetListData(wBin, IdPreparer){
    this.ParamShowClient["wBin"] = wBin;
    this.ParamShowClient["IdPreparer"] = IdPreparer;
    this.ParamShowClient["IdTicket"] = this.wIdTicket;   

    await this.http.ServerPost(this.http.root + "InComeTaxWorkFlow", this.ParamShowClient)
    .then(data => {
      this.ListData = JSON.stringify(data["data"]);
      this.ListData = JSON.parse(this.ListData);
      setTimeout(()=>{this.xFillGridWorkFlow();},1000);
      this.CheckClientOver24();
    }).catch(error => {
      console.log(error);
    });

    if (wBin > 1 || IdPreparer > 0){
      this.visibleFieldRemoveFilter = true;
    }else{
      this.visibleFieldRemoveFilter = false;
    }
  }

  getStringCommissionClaim(){

  }

  xFillGridWorkFlow(){
    $("#jQGridWorkFlow").jqGrid('GridUnload');
    var grid = $("#jQGridWorkFlow").jqGrid({
        datatype: "local",
        colNames: ['IdTicket', 'IdClient', 'Ticket #', 'Date Time', 'Type', 'Client Name', '', 'Bin', 'SSN', 'Subject', 'Data Entry', 'Preparer', 'Comm. Claimed', 'Quick Note'],
        colModel: [
            { name: 'IdTicket', hidden: true },
            { name: 'IdClient', hidden: true },
            
            { name: 'TicketNumber', width:75 },
            { name: 'DateTicket'},
            { name: 'TypeClient', width:75},
            { name: 'TaxpayerName', width:250 },
            { name: 'FotoURL', width: 15, hidden:true },

            {
                name: 'IdBin',
                editable: true, edittype: "select", formatter: 'select', editoptions: {
                    value: this.wSelectBin,
                    dataEvents: [
                        {
                            type: "change",
                            fn: function (e) {
                                var rowData = grid.getRowData(grid.getGridParam('selrow'));
                                //var IdClient = rowData['IdClient'];
                                //var IdTicket = rowData['IdTicket'];
                                //var BinName = this.options[this.selectedIndex].innerHTML;
                                //var FromBin = $("#SelectBin").val();

                                $("#IdClient").val(rowData['IdClient']);
                                $("#IdTicket").val(rowData['IdTicket']);
                                $("#BinName").val(this.options[this.selectedIndex].innerHTML);
                                $("#FromBin").val($("#SelectBins").val());
                                $("#IdSelectPrep").val($(e.target).val());
                                $("#ChangeValueToMoveBin").click();
                            }
                        }
                    ]
                }
            },

            { name: 'TaxpayerId', 
              cellattr: function () { return 'title="Click to Copy" cursor: hand'; }
            },

            { name: 'tSubject', width: 80 },
            {
              name: 'IdEmpDataEntry', editable: true, edittype: "select", formatter: 'select', editoptions: {
                  value: this.EmployeeList,
                  dataEvents: [
                      {
                          type: "change",
                          fn: function (e) {
                              var rowData = grid.getRowData(grid.getGridParam('selrow'));
                              
                              $("#IdClient").val(rowData['IdClient']);
                              $("#IdTicket").val(rowData['IdTicket']);
                              $("#IdSelectEmpDataEntry").val($(e.target).val());
                              $("#AplicaCambioDataEntry").click();
                          }
                      }
                  ]
              }
            },
            {
              name: 'IdPreparer', editable: true, edittype: "select", formatter: 'select', editoptions: {
                  value: this.PreparerList,
                  dataEvents: [
                      {
                          type: "change",
                          fn: function (e) {
                              var rowData = grid.getRowData(grid.getGridParam('selrow'));
                              //var IdClient = rowData['IdClient'];
                              //var IdTicket = rowData['IdTicket'];

                              $("#IdClient").val(rowData['IdClient']);
                              $("#IdTicket").val(rowData['IdTicket']);
                              $("#IdSelectPrep").val($(e.target).val());
                              $("#AplicaCambioPreparador").click();

                              //AplicaCambioPreparador($(e.target).val(), IdTicket, IdClient);
                          }
                      }
                  ]
              }
            },
            { 
              name: 'CommClaim'
            },            
            { name: 'QuickNote', width:450 }
        ],
        //loadComplete: function() {
          //grid.setColProp('IdBin', { editoptions: { value: this.wSelectBin} });
        //},
        data: this.ListData,
        caption: "Client List",
        pager: "#jqGridPager",
        viewrecords: true,
        height: "auto",
        cellEdit: true,
        autowidth: false,
        processing: true,
        paging: true,
        rowNum: 10,
        rowList: [10, 20, 30],
        cellsubmit: 'clientArray',
        
        beforeSelectRow: function(rowid, e)
        {
            //grid.jqGrid('resetSelection');
            //return true;
        },
        loadComplete: function() {
            $("tr.jqgrow:odd").css("background", "#E0ECF8");
        },

        onCellSelect: function (row, col, content, event, cellname) {
            //var $radio = $(event.target).closest('tr').find('input[type="radio"]');
            var rowData = $(this).getRowData(row);
            var IdClient = rowData['IdClient'];
            var IdTicket = rowData['IdTicket'];
            var TicketNum = rowData['TicketNumber'];
            var ClientSSN = rowData['TaxpayerId'];
            var cm = grid.jqGrid("getGridParam", "colModel");
            var colName = cm[col].name;
            $("#IdClient").val(IdClient);

            $("#IdTicket").val(IdTicket);
            $("#TicketNum").val(TicketNum);
            //$("#tmpCodClient").val(IdClient);
            //$("#spanTiecktID").val(TicketNum);

            if (colName == 'QuickNote') {
                //ShowPostItNote(IdTicket);
                $("#ShowPostItNote").click();
            }
            if (colName == 'CommClaim'){
              $('#ShowDivCommClaim').click();
            }
            if (colName == 'IdBin'){
              $("#StopInterval").click();
            }
            if (colName == 'IdPreparer'){
              $("#StopInterval").click();
            }
            if (colName == 'IdEmpDataEntry'){
              $("#StopInterval").click();
            }
            if (colName == 'TaxpayerId') {
              $("#ClipbordSSN").val(ClientSSN);
              //copyToClipboard(ClientSSN);
              $('#CopySSNToClipbord').click();
            }

            //$radio.attr('checked', 'checked');
            //return true; 

            //grid.jqGrid('setSelection',row); 
            
        }
    });
    
  }

  StopInterval(){
    clearInterval(this.interval);
   }

   InitInterval(){
    this.interval = setInterval(() => {
      if ($("#SelectBins").val() == 1){
        this.GetListData($("#SelectBins").val(), $("#SelectFilterPreparer").val());
      }
    }, 30000);
   }


  copy() {
    //window.getSelection().selectAllChildren(document.getElementById('QuickNoteTicket'));
    //document.execCommand("Copy");

    //var copyText = document.getElementById("ClipbordSSN");

    //let selBox; //= document.createElement('QuickNoteTicket');

    //selBox.style.position = 'fixed';
    //selBox.style.left = '0';
    //selBox.style.top = '0';
    //selBox.style.opacity = '0';
    //selBox = 'dsfdj'; //this.text.join('\r\n');

    //document.body.appendChild(selBox);
    //selBox.focus();
    //selBox.select();

    //$("#ClipbordSSN").attr('selected',selected');
    //document.execCommand('copy');
    //document.body.removeChild(selBox);

    //alert($("#ClipbordSSN").val());
}

  ShowDivCommClaim(){
    if($("#IdClient").val()==0){
      this.AlertWarning("Click On Client");
      return;
    }
    let IdEmp = parseInt(localStorage.getItem('employeeID'));
    let d = new Date();
    this.ClearCommClaim();
    this.IdClient = $("#IdClient").val();
    
    this.ParamShowClient["IdClient"] = this.IdClient;
    this.ParamShowClient["taxyear"] = d.getFullYear() - 1;
    this.http.ServerPost(this.http.root + "GetInfoCommClaim", this.ParamShowClient)
    .then(data => {
      if(data["hasdata"] == true){
        this.idprep = data["data"][0]["idprep"];
        this.idprep2=data["data"][0]["idprep2"];
        this.idprep3=data["data"][0]["idprep3"];
        this.idprep4=data["data"][0]["idprep4"];
        if(data["data"][0]["datecomm"]==null){
          this.datecomm = moment().format('L') + ' ' + moment().format('LT');
        }else{this.datecomm=moment(data["data"][0]["datecomm"]).format("YYYY-MM-DD");}
        if(data["data"][0]["datecomm2"]==null){
          this.datecomm2=this.datecomm = moment().format('L') + ' ' + moment().format('LT');
        }else{this.datecomm2=moment(data["data"][0]["datecomm2"]).format("YYYY-MM-DD");}
        if(data["data"][0]["datecomm3"]==null){
          this.datecomm3=this.datecomm = moment().format('L') + ' ' + moment().format('LT');
        }else{this.datecomm3=moment(data["data"][0]["datecomm3"]).format("YYYY-MM-DD");}
        if(data["data"][0]["datecomm4"]==null){
          this.datecomm4=this.datecomm = moment().format('L') + ' ' + moment().format('LT');
        }else{this.datecomm4=moment(data["data"][0]["datecomm4"]).format("YYYY-MM-DD");}
        this.porccomm=data["data"][0]["porccomm"];
        this.porccomm2=data["data"][0]["porccomm2"];
        this.porccomm3=data["data"][0]["porccomm3"];
        this.porccomm4=data["data"][0]["porccomm4"];
        this.ComentComm=data["data"][0]["commentcomm"];
        this.ComentComm2=data["data"][0]["commentcomm2"];
        this.ComentComm3=data["data"][0]["commentcomm3"];
        this.ComentComm4=data["data"][0]["commentcomm4"];

        if (IdEmp == 8 || IdEmp == 1 || IdEmp == 1091 || IdEmp == 14){
          this.visibleporccomm=true;
          this.visibleporccomm2=true;
          this.visibleporccomm3=true;
          this.visibleporccomm4=true;
        }else{
          if (IdEmp == this.idprep){
            this.visibleporccomm=true;
          }else{this.visibleporccomm=false;}
          if (IdEmp == this.idprep2){
            this.visibleporccomm2=true;
          }else{this.visibleporccomm2=false;}
          if (IdEmp == this.idprep3){
            this.visibleporccomm3=true;
          }else{this.visibleporccomm4=false;}
          if (IdEmp == this.idprep4){
            this.visibleporccomm4=true;
          }else{this.visibleporccomm4=false;}
        }
      }
    }).catch(error => {
      console.log(error);
    });

    this.openModal(this.divCommisionClaim);
  }

  ShowInfoClient(divModal: TemplateRef<any>){
    if ($("#IdTicket").val() == 0){
      this.AlertWarning("Click On Client");
      return;
    }
    this.ParamShowClient["IdClient"] = 0; //$("#IdClient").val();
    this.ParamShowClient["wTicket"] = $("#IdTicket").val();
    this.http.ServerPost(this.http.root + "show_client", this.ParamShowClient)
    .then(data => {
      if (data["hasdata"] == true){
        this.wIdTicket = $("#TicketNum").val();
        this.InfoPersonal = data["data"];
      }
    });
    this.modalRef = this.modalService.show(divModal, {class: 'modal-xl', backdrop: 'static'});
  }

  SaveInfoClient(f: NgForm){
    let wDateOD = f.value.DOBCustomer;
    f.value.IdClient = $("#IdClient").val();
    f.value.IdTicket = $("#IdTicket").val();
    f.value.TypeClient = 1;
    if (f.value.DOBCustomer == "00/00/0000"){
      f.value.DOBCustomer = '1900-01-01'
    }
    if (wDateOD == "Invalid date" || wDateOD == null){
      f.value.DOBCustomer = '1900-01-01'
    }
    if (wDateOD == '') {
      f.value.DOBCustomer = '1900-01-01'
    }
    else {f.value.DOBCustomer = moment(f.value.DOBCustomer).format("YYYY-MM-DD")};
    this.http.ServerPut(this.http.root + "save_client", f.value)
    .then(data => {
      this.IdClient = data["data"][0]["@MaxCod"];
      this.showSuccess("Info Saved");
      console.log(this.IdClient);
    }).catch(error => {
      console.log("error: " + error);
    });
  }

  openModal(divModal: TemplateRef<any>) {
    
    this.modalRef = this.modalService.show(divModal, {class: 'modal-xl', backdrop: 'static'});
  }

  UpdateBinPreparer(){
    var initInt;
    this.ParamS["IdPreparer"] = $("#IdSelectPrep").val();
    this.ParamS["IdTicket"] = $("#IdTicket").val();
    this.ParamS["IdClient"] = $("#IdClient").val();

    this.http.ServerPost(this.http.root + "UpdateBinPreparer", this.ParamS);

    setTimeout(()=>{
      this.InitInterval();
    },1000);
  }

  UpdateBinDataEntry(){
    this.ParamS["IdPreparer"] = $("#IdSelectEmpDataEntry").val();
    this.ParamS["IdTicket"] = $("#IdTicket").val();
    this.ParamS["IdClient"] = $("#IdClient").val();

    this.http.ServerPost(this.http.root + "UpdateBinDataEntry", this.ParamS);

    setTimeout(()=>{
      this.InitInterval();
    },1000);
  }

  ChangeValueToMoveBin(){
    //alert("wTicket: " + $("#IdTicket").val() + " wBin: " + $("#IdSelectPrep").val() + " wBinName: " + $("#BinName").val() + " From: " + $("#FromBin").val());

    $("#IdTicketToChangeBin").val($("#IdTicket").val());
    $("#IdBinFromChange").val($("#FromBin").val());
    $("#IdBinToChange").val($("#IdSelectPrep").val());
    $("#NameBinToMove").val($("#BinName").val());

    this.OpenNewModal("confirBin");
  }

  OpenNewModal(wDivModal){
    $("#BackgroundModalPopup").css({ "opacity": "0.5" });
    $("#BackgroundModalPopup").fadeIn();
    $("#" + wDivModal).fadeIn();
  }

  CloseModal(wDivModal){
    $("#BackgroundModalPopup").fadeOut();
    $("#" + wDivModal).fadeOut();
  }

  CancelMovingBin(){
    this.CloseModal("confirBin");
    //this.FillGridWorkFlow();
  }

  SecurityChangeBin(){
    var wTab = $("#IdBinFromChange").val();
    if (wTab == 1) {
        //Waiting List
        this.VerifySecurity(18,0);
    }
    if (wTab == 2) {
        //Working ON
        this.VerifySecurity(19,0);
    }
    if (wTab == 3) {
        //Preparer Later
        this.VerifySecurity(20,0);
    }
    if (wTab == 4) {
        //To Be Transmitted
        this.VerifySecurity(22,0);
    }
    if (wTab == 5) {
        //Pending IRS
        this.VerifySecurity(23,0);
    }
    if (wTab == 6) {
        //Pending Client
        this.VerifySecurity(24,0);
    }
    if (wTab == 7) {
        //Accepted
        this.VerifySecurity(25,0);
    }
    if (wTab == 8) {
        //Rejected
        this.VerifySecurity(26,0);
    }
    if (wTab == 9) {
        //Filed
        this.VerifySecurity(27,0);
    }
    if (wTab == 10) {
        //Back To Preparer
        this.VerifySecurity(21,0);
    }
    if (wTab == 11) {
      //Pending State
      this.VerifySecurity(34,0);
    }
    if (wTab == 13) {
      //Data Entry
      this.VerifySecurity(18,0);
    }
  }

  VerifySecurity(Md, ReportOpcion){
    var xdata;
    this.ParamS["wUserName"] = sessionStorage.getItem('IdentityName');
    this.ParamS["wModule"] = Md;
    this.http.ServerPost(this.http.root + "VerifySecurity", this.ParamS)
    .then(data => {
      if (data["data"][0]["Allow"] == 0){
        this.AlertWarning("Access Denied");
        return;
      }
      if (Md >= 18 && Md <= 27 || Md == 34) {
        this.EjecuteChangeBin();
      }
      switch (Md) {
          case 28:
              //jsPrintBin(ReportOpcion);
              //return true;
          case 29:
              this.ShowInfoClient(this.divCustomerInfo);
              return;
          case 32:
              this.AllowEditInfoClient();
              return;
      }
    }).catch(error => {
      //console.log("error sending: " + error);
      this.AlertWarning("Access Denied");
      return;
    });
  }

  EjecuteChangeBin(){
    var wT = $("#IdTicketToChangeBin").val();
    var wB = $("#IdBinToChange").val();

    this.ParamS["tStatus"] = wB;
    this.ParamS["IdTicket"] = wT;

    this.http.ServerPost(this.http.root + "UpdateTicketTaxWorkFlow", this.ParamS)
    .then(data => {
      this.CloseModal("confirBin");
      this.GetListData($("#SelectBins").val(),$("#SelectFilterPreparer").val());

      setTimeout(()=>{
        this.InitInterval();
      },1000);
    }).catch(error => {
      console.log("error sending Preparer");
    });
  }

  ShowPostItNote(){
    this.ParamS["IdTicket"] = $("#IdTicket").val();

    this.http.ServerPost(this.http.root + "ShowPostItNote", this.ParamS)
    .then(data => {
      $("#ChangePostIt").val(data["data"][0]["QuickNote"]);
      this.OpenNewModal("ModalPostIt");
    }).catch(error => {
      console.log("error geting note: " + error);
    });
  }

  ApplyPostItNote(){
    this.ParamS["IdTicket"] = $("#IdTicket").val();
    this.ParamS["wNote"] = $("#ChangePostIt").val();

    this.http.ServerPost(this.http.root + "UpdatePostItNote", this.ParamS);
    this.CloseModal('ModalPostIt');
    setTimeout(()=>{
      this.GetListData($("#SelectBins").val(),$("#SelectFilterPreparer").val());
    },1000);

  }

  RowClick(IdClient, IdTicket){
    //this.ShowInfoClient(IdClient);
    this.wIdTicket = IdTicket;
    this.IdClient = $("#IdClient").val();
  }

  ShowFindCustomer(){
    //this.clearInterval(wInterval);
    //$("#BackgroundModalPopup").css({ "opacity": "0.5" });
    //$("#BackgroundModalPopup").fadeIn();
    //$("#divFindClient").fadeIn();
    this.ArrayListTIcket = [];
    this.openModal(this.divFindClient);
  }

  IntroSSN(){
    this.findClient();
   }

  findClient(){
    this.ParamS = {};
    this.ParamS["SSN"] = $("#FindSSNWorkFlow").val();
    this.ParamS["wYear"] = 2023; //$("#YearFindWorkFlow").val();
    this.ParamS["wCustomerName"]="";
console.log(this.ParamS);
    this.http.ServerPost(this.http.root + "FindClientWorkFlow", this.ParamS)
    .then(data => {
      this.InfoPersonal = data["data"];
      if (this.InfoPersonal.length == 0){
        this.AlertWarning("Client Not Found");
        $("#ResulFindClient").fadeOut(); 
        return 
      }
      var IdBin = data["data"][0]["IdBin"];
        if (IdBin > 0) {
            $("#NotFindClient").fadeOut();
            $("#SelectBins").val(IdBin);
        }

        this.ParamS["ssncl"] = data["data"][0]["TaxpayerId"];
        $("#NameFindClient").val(data["data"][0]["TaxPayerName"]);
        $("#NameBinFindClient").val(data["data"][0]["NameBin"]);
        $("#TicketNoFindClient").val(data["data"][0]["TicketNumber"]);
        
        this.wIdTicket = data["data"][0]["IdTicket"];

        $("#ResulFindClient").fadeIn();

        this.GetListData(IdBin,$("#SelectFilterPreparer").val());
        this.FillListTicketsReceptions();

        this.wIdTicket = 0;

    }).catch(error => {
      console.log("error sending data: " + error);
    });
  }

  CloseFindCustomer(){
    $("#BackgroundModalPopup").fadeOut();
    $("#divFindClient").fadeOut();
    $("#FindSSNWorkFlow").val("");
    $("#ResulFindClient").fadeOut();
  }

  showmenu(menu){
    $("#" + menu).fadeIn();
   }

  hidemenu(menu){
    $("#" + menu).fadeOut();
  }

  ClearCommClaim(){
    this.idprep = 47;
    this.idprep2=47;
    this.idprep3=47;
    this.idprep4=47;
    this.datecomm= moment().format('L') + ' ' + moment().format('LT');
    this.datecomm2= moment().format('L') + ' ' + moment().format('LT');
    this.datecomm3= moment().format('L') + ' ' + moment().format('LT');
    this.datecomm4= moment().format('L') + ' ' + moment().format('LT');
    this.porccomm=20;
    this.porccomm2=20;
    this.porccomm3=20;
    this.porccomm4=20;
    this.ComentComm='';
    this.ComentComm2='';
    this.ComentComm3='';
    this.ComentComm4='';
    this.visibleporccomm = false;
    this.visibleporccomm2= false;
    this.visibleporccomm3= false;
    this.visibleporccomm4 = false;
  }

  SubmitCommissionClaim(f: NgForm){
    this.ParamS["CommentComm"]=f.controls.CommentComm.value;
    this.ParamS["CommentComm2"]=f.controls.CommentComm2.value;
    this.ParamS["CommentComm3"]=f.controls.CommentComm3.value;
    this.ParamS["CommentComm4"]=f.controls.CommentComm4.value;
    this.ParamS["DateComm"]=f.controls.DateComm.value;
    this.ParamS["DateComm2"]=f.controls.DateComm2.value;
    this.ParamS["DateComm3"]=f.controls.DateComm3.value;
    this.ParamS["DateComm4"]=f.controls.DateComm4.value;
    this.ParamS["IdClient"]=f.controls.IdClient.value;
    this.ParamS["PorcComm"]=f.controls.PorcComm.value;
    this.ParamS["PorcComm2"]=f.controls.PorcComm2.value;
    this.ParamS["PorcComm3"]=f.controls.PorcComm3.value;
    this.ParamS["PorcComm4"]=f.controls.PorcComm4.value;
    this.ParamS["PreCommClaim"]=f.controls.PreCommClaim.value;
    this.ParamS["PreCommClaim2"]=f.controls.PreCommClaim2.value;
    this.ParamS["PreCommClaim3"]=f.controls.PreCommClaim3.value;
    this.ParamS["PreCommClaim4"]=f.controls.PreCommClaim4.value;
    this.http.ServerPost(this.http.root + "SaveCommissionClaim", this.ParamS)
    .then(data => {
      if(data["success"] == true){
        this.showSuccess("Info Saved!!");
        this.GetListData($("#SelectBins").val(),$("#SelectFilterPreparer").val());
        this.ModalRef();
      }
    }).catch(error => {
      console.log(error);
    });
  }

  verifyPass(wIdPrep){
    if(this.ScCod1 == this.UserPass){
      if (this.idprep != 47){
        if (wIdPrep == this.idprep){
          this.DisabledField1 =false;
          this.idprep = wIdPrep;
          this.visibleporccomm = true;  
          return;
        }
        if (wIdPrep != 1 && wIdPrep != 8 && wIdPrep != 1091 && wIdPrep != 14) {
          this.AlertWarning("Access Denied")
          return;
        }
        if (wIdPrep == 1 || wIdPrep == 8 || wIdPrep == 1091 && wIdPrep != 14){
          this.DisabledField1 =false;
          this.idprep = this.idprep;
          this.visibleporccomm = true;  
          return;
        }
      }
      this.DisabledField1 =false;
      this.idprep = wIdPrep;
      this.visibleporccomm = true;
    }
    if(this.ScCod2 == this.UserPass){
      if (this.idprep2 != 47){
        if (wIdPrep == this.idprep2){
          this.DisabledField2 =false;
          this.idprep2 = wIdPrep;
          this.visibleporccomm2 = true;  
          return;
        }
        if (wIdPrep != 1 && wIdPrep != 8 && wIdPrep != 1091 && wIdPrep != 14){
          this.AlertWarning("Access Denied")
          return;
        }
        if (wIdPrep == 1 || wIdPrep == 8 || wIdPrep == 1091 && wIdPrep != 14){
          this.DisabledField2 =false;
          this.idprep2 = this.idprep2;
          this.visibleporccomm2 = true;  
          return;
        }
      }
      this.DisabledField2 =false;
      this.idprep2 = wIdPrep
      this.visibleporccomm2 = true;
    }
    if(this.ScCod3 == this.UserPass){
      if (this.idprep3 != 47){
        if (wIdPrep == this.idprep3){
          this.DisabledField3 =false;
          this.idprep3 = wIdPrep;
          this.visibleporccomm3 = true;  
          return;
        }
        if (wIdPrep != 1 && wIdPrep != 8 && wIdPrep != 1091 && wIdPrep != 14){
          this.AlertWarning("Access Denied")
          return;
        }
        if (wIdPrep == 1 || wIdPrep == 8 || wIdPrep == 1091 && wIdPrep != 14){
          this.DisabledField3 =false;
          this.idprep3 = this.idprep3;
          this.visibleporccomm3 = true;  
          return;
        }
      }
      this.DisabledField3 =false;
      this.idprep3 = wIdPrep
      this.visibleporccomm3 = true;
    }
    if(this.ScCod4 == this.UserPass){
      if (this.idprep4 != 47){
        if (wIdPrep == this.idprep4){
          this.DisabledField4 =false;
          this.idprep4 = wIdPrep;
          this.visibleporccomm4 = true;  
          return;
        }
        if (wIdPrep != 1 && wIdPrep != 8 && wIdPrep != 1091 && wIdPrep != 14){
          this.AlertWarning("Access Denied")
          return;
        }
        if (wIdPrep == 1 || wIdPrep == 8 || wIdPrep == 1091 && wIdPrep != 14){
          this.DisabledField4 =false;
          this.idprep4 = this.idprep4;
          this.visibleporccomm4 = true;  
          return;
        }
      }
      this.DisabledField4 =false;
      this.idprep4 = wIdPrep
      this.visibleporccomm4 = true;
    }
    
  }

  verifyPassByButton(){
    this.AlertWarning("Password not valid"); return;
   }

  ModalRef(){
    this.modalRef.hide();
    this.ScCod1='';
    this.ScCod2='';
    this.ScCod3='';
    this.ScCod4='';
    this.DisabledField1 =true;
    this.DisabledField2 =true;
    this.DisabledField3 =true;
    this.DisabledField4 =true;
  }

  AlertWarning(msg) {
    this.toastr.warningToastr(msg, 'Alert', {toastLife: 5000, showCloseButton:true});
    }

  AlertError(msg) {
    this.toastr.errorToastr(msg, 'Error', {toastLife: 5000, showCloseButton:true});
    } 

  showSuccess(msg){
    this.toastr.successToastr(msg, 'Success!', {toastLife: 2000, showCloseButton:true});
    }

  AllowEditInfoClient(){
    this.isEditable = false;
    }

  async VerifyPrepPass(wPass){
    let rIdPrep;
    let wIdPrep;
    rIdPrep = await this.http.VerifyPrepPass(wPass);

    if (rIdPrep == 0){
      return 0;
    }else{
      this.UserPass = wPass;
      this.verifyPass(rIdPrep);
      }
  }

  OpenProcedures(){
    if ($("#IdClient").val() == 0){
      this.AlertWarning("Click On Client");
      return;
    }
    this.GetProcRec();
    this.openModal(this.divProcedure);
  }

  GetProcRec(){
    var d = new Date();
    var n = d.getFullYear() - 1;
    this.ParamS["TaxYear"] = n;
    this.ParamS["IdClient"] = $("#IdClient").val();
    this.http.ServerPost(this.http.root + "get_ProcRec", this.ParamS)
    .then(data => {
      if (data["hasdata"]==true){
        this.Proc_Reception = data["data"];
      }
    }).catch(error => {
      console.log(error);
    });
  }

  ClearDataClient(){
    this.Proc_Reception = [
      {
        proc1:0,proc2:0,proc3:0,proc4:0,proc5:0,proc6:0,proc7:0,proc8:0,proc9:0,proc10:0,
        proc11:0,proc12:0,proc13:0,proc14:0,proc15:0,proc16:0,

        proc17:0,proc18:0,proc19:0,proc20:0,proc21:0,proc22:0,proc23:0,proc24:0,proc25:0,proc26:0,

        proc27:0,proc28:0,proc29:0,proc30:0,proc31:0,proc32:0,proc33:0,proc34:0,proc35:0,proc36:0,
        proc37:0,proc38:0,proc39:0,proc40:0, proc41:0, proc42:0, proc43:0, proc44:0, proc45:0,
      proc46:0, proc47:0, proc48:0, proc49:0, proc50:0, proc51:0, proc52:0, proc53:0
      }
    ];
  }

  async SaveProcRec(f:NgForm){
    var res;
    res = await this.ItCanSaveClient(f);
    if (!res){
      return;
    }
    f.value.IdClient = $("#IdClient").val();

    var d = new Date();
    var n = d.getFullYear() - 1;
    f.value.TaxYear = n;

    this.http.clientput(this.http.root + "save_ProcRec", f.value);
    this.showSuccess("Procedure Saved !!");
  }

  async ItCanSaveClient(f: NgForm){
    if (this.UserPass != f.value.SecCode){
      this.AlertWarning("Username Password Missing");
      return false;
    }

    return true;
    
  }

  CheckClientOver24(){
    this.ParamS["IdPrep"] = localStorage.getItem('employeeID');

    this.http.ServerPost(this.http.root + "CheckClientOver24h", this.ParamS)
    .then(data => {
      if (data["success"] == true){
        this.visibleFieldOver24h = true;
      }else{
        this.visibleFieldOver24h = false;
      }
    })
  }

  async FilterClientOver24(){
    var v_IdEmp = localStorage.getItem('employeeID');
    this.ParamShowClient["IdPrep"] = v_IdEmp;

    await this.http.ServerPost(this.http.root + "FilterClientOver24h", this.ParamShowClient)
    .then(data => {
      this.ListData = JSON.stringify(data["data"]);
      this.ListData = JSON.parse(this.ListData);
      this.xFillGridWorkFlow();

      $("#SelectFilterPreparer").val(v_IdEmp);
      $("#SelectBins").val(2);
      this.visibleFieldRemoveFilter=true;
    }).catch(error => {
      console.log("error Filter Client " + error);
    });
  }

  RemovingFilter(){
    $("#SelectFilterPreparer").val(0);
    $("#SelectBins").val(1);
    this.visibleFieldRemoveFilter = false;
  }

  alertMultiRefresh(){
    if (this.countRefresh > 1){
      this.router.navigate(['dashboard']);
      return;
    }
    var vt = moment();
    var tt = vt.diff(this.timeInit,'minutes');
    if (tt < 1){
      this.countRefresh = this.countRefresh + 1;
      alert("Please do not refresh many time");
    }
    this.timeInit = moment();
  }

  FillListTicketsReceptions(){
    this.http.ServerPost(this.http.root + "ShowListClientTicket", this.ParamS)
    .then(data => {
      this.ArrayListTIcket = data["data"];
    }).catch(error => {
      console.log("error showing Clients tickets " + error);
    });
  }

}
