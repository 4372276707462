import { WebserviceService } from '../../webservice.service';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';

interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

//let http = 'http://localhost:4200/#/'; //new WebserviceService();
let xhttp = new WebserviceService(null,null);
let yhttp = xhttp.getUrl();
let http = 'http://' + yhttp + '/#/'; //xhttp.root;

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fa fa-home fa-fw'
  },
  {
    name: 'Save',
    url: http + 'user?method=save',
    icon: 'fa fa fa-floppy-o fa-fw'
  },
  {
    name: 'New',
    url: http + 'user?method=new',
    icon: 'fa fa-plus-circle fa-fw'
  },
  {
    name: 'Change Photo',
    url: http + 'user?method=changephoto',
    icon: 'fa fa fa-camera fa-fw'
  }
];
