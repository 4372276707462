import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

import * as io from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class WebserviceService {
  
  root = 'http://69.87.220.221:3000/';
  //root = 'http://localhost:3000/';

  ParamS:any = {};
  publicIP: string;
  
  public isStatusClientChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public CompleteUserName: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public NewSmsComing: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private socket: SocketIOClient.Socket;

  constructor(private _http:Http, private myRoute: Router) { this.socket = io.connect(this.root);}

  sendToken(token: string) {
    localStorage.setItem("LoggedInUser", token);
  }
  getToken() {
    return localStorage.getItem("LoggedInUser")
  }
  isLoggedIn() {
    return this.getToken() !== null;
  }
  logout() {
    localStorage.removeItem("LoggedInUser");
    this.myRoute.navigate(["./login"]);
  }
  getUrl(){
    return window.location.host;
  }

  getPreparer(url:string){
    return new Promise(resolve =>{
      this._http.get(url)
      .subscribe(data => {
        resolve(data.json());
      },err => {
        console.log(err);
        resolve(err);
      })
    });
  }

  postShowClient(url:string, body:any){
    return new Promise(resolve =>{
      this._http.post(url,body)
      .subscribe(data => {
        resolve(data.json());
      },err => {
        console.log(err);
        resolve(err);
      })
    });
  }

  getTicketNumber(url:string){
    return new Promise(resolve =>{
      this._http.get(url)
      .subscribe(data => {
        resolve(data.json());
      },err => {
        console.log("get ticket number error: " + err);
        resolve(err);
      })
    });
  }

  postCreateTicket(url:string, body:any){
    return new Promise(resolve =>{
      this._http.post(url,body)
      .subscribe(data => {
        resolve(data.json());
      },err => {
        console.log("post Creating Ticket error: " + err);
        resolve(err);
      })
    });
  }

  ShowXmlTicket(url:string, wTicket:number){
    return new Promise(resolve =>{
      this._http.post(url, wTicket)
      .subscribe(data => {
        resolve(data.json());
      },err => {
        console.log("show xml ticket error: " + err);
        resolve(err);
      })
    });
  }

  clientput(url:string, body:any){
    return new Promise(resolve => {
      this._http.put(url,body)
      .subscribe(data => {
        resolve(data.json());
      }, err =>{
        console.log(err);
        resolve(err);
      })
    });
  }

  ServerGet(url:string){
    return new Promise(resolve =>{
      this._http.get(url)
      .subscribe(data => {
        resolve(data.json());
      },err => {
        console.log("get server error: " + err);
        resolve(err);
      })
    });
  }

  ServerPost(url:string, body:any){
    let headers:Headers = this.createAuthorizationHeaderJson();
    return new Promise(resolve =>{
      this._http.post(url,body)
      .subscribe(data => {
        resolve(data.json());
      },err => {
        console.log(err);
        resolve(err);
      })
    });
  }

  ServerPut(url:string, body:any){
    return new Promise(resolve => {
      this._http.put(url,body)
      .subscribe(data => {
        //console.log(data.json());
        resolve(data.json());
      }, err =>{
        console.log("error puting client ", err );
        resolve(err);
      })
    });
  }

  FillListTicketsReception(url:string, body:any){
    return new Promise(resolve => {
      this._http.post(url,body)
      .subscribe(data => {
        resolve(data.json());
      }, err =>{
        console.log("error filling ticket ", err );
        resolve(err);
      })
    });
  }

  ServerConfirmPassword(wPass:any){
    return new Promise(resolve => {
      this._http.get(this.root + ":" + wPass)
      .subscribe(data => {
        resolve(data.json());
      }, err =>{
        console.log("error confirming pass ", err );
        resolve(err);
      })
    });
  }

  VerifyPrepPass(wPass){
    let wIdPrep;
    this.ParamS["wPass"] = wPass;
    return this.ServerPost(this.root + "VerifyPrepPass", this.ParamS)
    .then(data => {
      if (data["success"] == true){
        wIdPrep = data["data"]["EmployeeID"];
        return wIdPrep;
      }else{
        return 0;
      }
    });
  }

  GetUseIp(){
    let result;
    return new Promise(resolve => {
      this._http.get('http://api.ipstack.com/check?access_key=eda6f626afc5d024f19fb99cc3f733fc')
      .subscribe(data => {
        resolve(data.json());
      }, err =>{
        console.log("error get user ip ", err );
        resolve(err);
      })
    });

  }

  onNewSMS() {
    return Observable.create(observer => {
      this.socket.on('newsms', msg => {
        observer.next(msg);
      });
    });
  }

  createAuthorizationHeaderJson() :Headers {
    let headers:Headers = new Headers();
    headers.append('Access-Control-Allow-Origin', 'http://localhost:4200');
    headers.append('Access-Control-Allow-Methods', 'POST');
    headers.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');
    //headers.append('Access-Control-Allow-Credentials: true');  
    //headers.append('Authorization', localStorage.getItem("token"));
    return headers;
  }
}
